const person = {
  routes: [
    {
      path: "/mine",
      name: "mine",
      redirect: "/myCourseList",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
      },
      component: () => import("@/view/person/mine.vue"),
      children: [
        {
          path: "/myCourseList",
          name: "myCourseList",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },
          component: () => import("@/view/person/myList/myCourseList.vue"),
        },
        {
          path: "/myExamList",
          name: "myExamList",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },

          component: () => import("@/view/person/myList/myExamList.vue"),
        },
        {
          path: "/pwdEdit",
          name: "pwdEdit",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },

          component: () => import("@/view/person/myList/pwdEdit.vue"),
        },
        {
          path: "/msgEdit",
          name: "msgEdit",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },

          component: () => import("@/view/person/myList/msgEdit.vue"),
        },
        {
          path: "/studyShow",
          name: "studyShow",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },
          component: () => import("@/view/person/myList/studyShow.vue"),
        },
        {
          path: "/myTrainList",
          name: "myTrainList",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },

          component: () => import("@/view/person/myList/myTrainList.vue"),
        },
        {
          path: "/myOrder",
          name: "myOrder",
          meta: {
            keepAlive: false,
            showHeader: true,
            showFooter: true,
            isShowNav: true,
            isSkip: false,
          },

          component: () => import("@/view/person/myList/myOrder.vue"),
        },
      ],
    },
    {
      path: "/aboutUs",
      name: "aboutUs",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/aboutus/aboutUs.vue"),
    },
    {
      path: "/safetyProductionTraining",
      name: "safetyProductionTraining",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/aboutus/safetyProductionTraining.vue"),
    },
    {
      path: "/safetyreview",
      name: "safetyreview",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/aboutus/safetyreview.vue"),
    },
    {
      path: "/safetylevel",
      name: "safetylevel",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/aboutus/safetylevel.vue"),
    },
  ],
};
export default person;

const train = {
  routes: [
    {
      path: "/trainList",
      name: "trainList",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/train/trainList.vue"),
    },
    {
      path: "/trainShow",
      name: "trainShow",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/trainShow.vue"),
    },
    {
      path: "/courseShow",
      name: "courseShow",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/courseShow.vue"),
    },
    {
      path: "/play",
      name: "play",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/play.vue"),
    },
    {
      path: "/examShow",
      name: "examShow",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/examShow.vue"),
    },
    {
      path: "/examResult",
      name: "examResult",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/examResult.vue"),
    },
    {
      path: "/groupRegistration",
      name: "groupRegistration",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/groupRegistration.vue"),
    },
    {
      path: "/individualRegistration",
      name: "individualRegistration",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: false,
      },
      component: () => import("@/view/train/individualRegistration.vue"),
    },
    {
      path: "/toExamine",
      name: "toExamine",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
        isSkip: false,
      },
      component: () => import("@/view/train/toExamine.vue"),
    },
    {
      path: "/auditForm",
      name: "auditForm",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
        isSkip: true,
      },
      component: () => import("@/view/train/auditForm.vue"),
    },
    {
      path: "/forAuditForm",
      name: "forAuditForm",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
        isSkip: true,
      },
      component: () => import("@/view/train/forAuditForm.vue"),
    },
    {
      path: "/play/tryplay",
      name: "tryplay",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/train/tryplay.vue"),
    },
    {
      path: "/batchPrintingTraining",
      name: "batchPrintingTraining",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
        isSkip: true,
      },
      component: () => import("@/view/train/batchPrintingTraining.vue"),
    }
  ],
};
export default train;

const user = {
  routes: [
    {
      path: "/login",
      name: "login",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
        isSkip: true,
      },
      component: () => import("@/view/user/login.vue"),
    },
    {
      path: "/register",
      name: "register",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: false,
        isSkip: true,
      },
      component: () => import("@/view/user/register.vue"),
    },
    {
      path: "/forget",
      name: "forget",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/user/forget.vue"),
    },
    {
      path: "/userOrderPage",
      name: "userOrderPage",
      meta: {
        showHeader: false,
        showFooter: false,
        keepAlive: false,
        isShowNav: false,
        isSkip: true,
      },
      component: () => import("@/view/backstage/userOrderPage.vue"),
    },
  ],
};
export default user;

import Api from "./api";
import fetch from "./fetch";

//判断能否访问页面接口
export const Authorization = (data) =>
  fetch.post(Api.Authorization.url, { ...Api.Authorization.data, ...data });
//保持在线
export const KeepOnline = (data) =>
  fetch.get(Api.KeepOnline.url, { ...Api.KeepOnline.data, ...data });
//文章分类
export const ArticleCategory = (data) =>
  fetch.post(Api.ArticleCategory.url, { ...Api.ArticleCategory.data, ...data });
//文章
export const ArticleList = (data) =>
  fetch.post(Api.ArticleList.url, { ...Api.ArticleList.data, ...data });
//文章内容

export const ArticleContent = (data) =>
  fetch.post(Api.ArticleContent.url, { ...Api.ArticleContent.data, ...data });


  export const GetGroupType = (data) =>
  fetch.post(Api.GetGroupType.url, { ...Api.GetGroupType.data, ...data });
//搜索全部新闻
export const SearchAll = (data) =>
  fetch.post(Api.SearchAll.url, { ...Api.SearchAll.data, ...data });
//通知公告

export const noticeAnnouncement = (data) =>
  fetch.post(Api.noticeAnnouncement.url, {
    ...Api.noticeAnnouncement.data,
    ...data,
  });
//通告公告列表
export const NoticeList = (data) =>
  fetch.post(Api.NoticeList.url, { ...Api.NoticeList.data, ...data });
//通知内容
export const noticeContent = (data) =>
  fetch.post(Api.noticeContent.url, { ...Api.noticeContent.data, ...data });
//友情链接
export const Blogroll = (data) =>
  fetch.post(Api.Blogroll.url, { ...Api.Blogroll.data, ...data });
//培训课程
export const TrainingClass = (data) =>
  fetch.post(Api.TrainingClass.url, { ...Api.TrainingClass.data, ...data });
//请求用户信息
export const LoginShort = (data) =>
  fetch.post(Api.LoginShort.url, { ...Api.LoginShort.data, ...data });
//防伪造请求
export const AntiForgeryToken = (data) =>
  fetch.post(Api.AntiForgeryToken.url, {
    ...Api.AntiForgeryToken.data,
    ...data,
  });
//用户退出
export const LoginOut = (data) =>
  fetch.post(Api.LoginOut.url, { ...Api.LoginOut.data, ...data });
//踢出其他地方登录账号
export const KickOut = (data) =>
  fetch.post(Api.KickOut.url, { ...Api.KickOut.data, ...data });
//点击登陆（记住密码）
export const LoginMe = (data) =>
  fetch.post(Api.LoginMe.url, { ...Api.LoginMe.data, ...data });
//点击登陆（没有记住密码）
export const LoginCode = (data) =>
  fetch.post(Api.LoginCode.url, { ...Api.LoginCode.data, ...data });
//登陆页面登录（验证码）
export const Login = (data) =>
  fetch.post(Api.Login.url, { ...Api.Login.data, ...data });
//获取验证码
export const GetVerifyCode = (data) =>
  fetch.get(Api.GetVerifyCode.url, { ...Api.GetVerifyCode.data, ...data });
/*//获取验证码
GetVerifyCode,*/
//记住密码
export const GetLoginName = (data) =>
  fetch.post(Api.GetLoginName.url, { ...Api.GetLoginName.data, ...data });
// 获取二维码
export const GetLoginQRCode = (data) =>
  fetch.post(Api.GetLoginQRCode.url, { ...Api.GetLoginQRCode.data, ...data });
// 轮训扫码结果
export const CheckLoginQRCode = (data) =>
  fetch.post(Api.CheckLoginQRCode.url, {
    ...Api.CheckLoginQRCode.data,
    ...data,
  });
//单位排行
export const LeftGroupRank = (data) =>
  fetch.post(Api.LeftGroupRank.url, { ...Api.LeftGroupRank.data, ...data });
//单位排行详情
export const RankGroupList = (data) =>
  fetch.post(Api.RankGroupList.url, { ...Api.RankGroupList.data, ...data });
//实时数据
export const LeftRealTimeData = (data) =>
  fetch.post(Api.LeftRealTimeData.url, {
    ...Api.LeftRealTimeData.data,
    ...data,
  });
//用户素质维度
export const GetUserSkill = (data) =>
  fetch.post(Api.GetUserSkill.url, { ...Api.GetUserSkill.data, ...data });
//课程分类
export const CourseCategory = (data) =>
  fetch.post(Api.CourseCategory.url, { ...Api.CourseCategory.data, ...data });
//推荐课程
export const RecommendCourse = (data) =>
  fetch.post(Api.RecommendCourse.url, { ...Api.RecommendCourse.data, ...data });
//相关课程
export const RelatedCourse = (data) =>
  fetch.post(Api.RelatedCourse.url, { ...Api.RelatedCourse.data, ...data });
//课程列表
export const CourseList = (data) =>
  fetch.post(Api.CourseList.url, { ...Api.CourseList.data, ...data });
//课程点击排行
export const CourseClickRank = (data) =>
  fetch.post(Api.CourseClickRank.url, { ...Api.CourseClickRank.data, ...data });
//课程点击排行详细页
export const CourseClickList = (data) =>
  fetch.post(Api.CourseClickList.url, { ...Api.CourseClickList.data, ...data });
//登录次数排行
export const LoginSumRank = (data) =>
  fetch.post(Api.LoginSumRank.url, { ...Api.LoginSumRank.data, ...data });
//登录次数排行详细页
export const LoginSumList = (data) =>
  fetch.post(Api.LoginSumList.url, { ...Api.LoginSumList.data, ...data });
//批量选课
export const AddStudyCourse = (data) =>
  fetch.post(Api.AddStudyCourse.url, { ...Api.AddStudyCourse.data, ...data });
//个人中心个人信息
export const LoginLong = (data) =>
  fetch.post(Api.LoginLong.url, { ...Api.LoginLong.data, ...data });
//学员学时排行
export const LeftUserRank = (data) =>
  fetch.post(Api.LeftUserRank.url, { ...Api.LeftUserRank.data, ...data });
//个人排行
export const RankUserList = (data) =>
  fetch.post(Api.RankUserList.url, { ...Api.RankUserList.data, ...data });
//课程完成排行
export const CourseFinishList = (data) =>
  fetch.post(Api.CourseFinishList.url, {
    ...Api.CourseFinishList.data,
    ...data,
  });
//个人中心课程
export const MyCenter = (data) =>
  fetch.post(Api.MyCenter.url, { ...Api.MyCenter.data, ...data });
//考试中心列表
export const ExamList = (data) =>
  fetch.post(Api.ExamList.url, { ...Api.ExamList.data, ...data });
//问卷调查列表
export const PollList = (data) =>
  fetch.post(Api.PollList.url, { ...Api.PollList.data, ...data });
//参加问卷调查
export const Poll = (data) =>
  fetch.post(Api.Poll.url, { ...Api.Poll.data, ...data });
//参加问卷调查2
export const Poll2 = (data) =>
  fetch.post(Api.Poll2.url, { ...Api.Poll2.data, ...data });
//问卷调查结果
export const PollReview = (data) =>
  fetch.post(Api.PollReview.url, { ...Api.PollReview.data, ...data });

export const PostPoll = (data) =>
  fetch.post(Api.PostPoll.url, { ...Api.PostPoll.data, ...data });
//问卷调查结果2
export const PollReview2 = (data) =>
  fetch.post(Api.PollReview2.url, { ...Api.PollReview2.data, ...data });
//培训班分类
export const GetTrainingClassTypeList = (data) =>
  fetch.post(Api.GetTrainingClassTypeList.url, {
    ...Api.GetTrainingClassTypeList.data,
    ...data,
  });
//专题学习
export const StudySpecial = (data) =>
  fetch.post(Api.StudySpecial.url, { ...Api.StudySpecial.data, ...data });
//我的班
export const ClassMy = (data) =>
  fetch.post(Api.ClassMy.url, { ...Api.ClassMy.data, ...data });
//活跃班级
export const ClassActive = (data) =>
  fetch.post(Api.ClassActive.url, { ...Api.ClassActive.data, ...data });
//近期班级
export const ClassRecent = (data) =>
  fetch.post(Api.ClassRecent.url, { ...Api.ClassRecent.data, ...data });
//获取培训列表
export const GetClassList = (data) =>
  fetch.post(Api.GetClassList.url, { ...Api.GetClassList.data, ...data });
//我的班级（type："my" 我的班级 type："recent" 近期班级 type："active" 活跃班级）
export const ClassList = (data) =>
  fetch.post(Api.ClassList.url, { ...Api.ClassList.data, ...data });
//课程详情
export const CourseContent = (data) =>
  fetch.post(Api.CourseContent.url, { ...Api.CourseContent.data, ...data });
//学习统计
export const MyStudyStat = (data) =>
  fetch.post(Api.MyStudyStat.url, { ...Api.MyStudyStat.data, ...data });
//考试统计
export const MyExamStat = (data) =>
  fetch.post(Api.MyExamStat.url, { ...Api.MyExamStat.data, ...data });
//我的收藏
export const MyFavorite = (data) =>
  fetch.post(Api.MyFavorite.url, { ...Api.MyFavorite.data, ...data });
//收藏
export const FavoriteAdd = (data) =>
  fetch.post(Api.FavoriteAdd.url, { ...Api.FavoriteAdd.data, ...data });
//取消收藏
export const FavoriteDelete = (data) =>
  fetch.post(Api.FavoriteDelete.url, { ...Api.FavoriteDelete.data, ...data });
//学习计划
export const MyStudyPlan = (data) =>
  fetch.post(Api.MyStudyPlan.url, { ...Api.MyStudyPlan.data, ...data });
//获取笔记
export const NoteAdd = (data) =>
  fetch.post(Api.NoteAdd.url, { ...Api.NoteAdd.data, ...data });
//添加笔记
export const AddNote = (data) =>
  fetch.post(Api.AddNote.url, { ...Api.AddNote.data, ...data });
//笔记列表
export const CourseNoteList = (data) =>
  fetch.post(Api.CourseNoteList.url, { ...Api.CourseNoteList.data, ...data });
//编辑笔记
export const NoteUpdate = (data) =>
  fetch.post(Api.NoteUpdate.url, { ...Api.NoteUpdate.data, ...data });
//删除笔记
export const DelNote = (data) =>
  fetch.post(Api.DelNote.url, { ...Api.DelNote.data, ...data });

//添加计划
export const StudyPlanAdd = (data) =>
  fetch.post(Api.StudyPlanAdd.url, { ...Api.StudyPlanAdd.data, ...data });
//添加计划提交
export const AddStudyPlan = (data) =>
  fetch.post(Api.AddStudyPlan.url, { ...Api.AddStudyPlan.data, ...data });
//查看计划
export const StudyPlanUpdate = (data) =>
  fetch.post(Api.StudyPlanUpdate.url, { ...Api.StudyPlanUpdate.data, ...data });
//提交修改计划
export const EditStudyPlanUpdate = (data) =>
  fetch.post(Api.EditStudyPlanUpdate.url, {
    ...Api.EditStudyPlanUpdate.data,
    ...data,
  });
//删除课程
export const DelUserCourseReg = (data) =>
  fetch.post(Api.DelUserCourseReg.url, {
    ...Api.DelUserCourseReg.data,
    ...data,
  });
//考试查看
export const CourseExamList = (data) =>
  fetch.post(Api.CourseExamList.url, { ...Api.CourseExamList.data, ...data });
//删除学习计划
export const DelStudyPlan = (data) =>
  fetch.post(Api.DelStudyPlan.url, { ...Api.DelStudyPlan.data, ...data });
//留言板
export const MessageList = (data) =>
  fetch.post(Api.MessageList.url, { ...Api.MessageList.data, ...data });
//留言信息详情
export const MessageDetail = (data) =>
  fetch.post(Api.MessageDetail.url, { ...Api.MessageDetail.data, ...data });
//提交添加留言
export const GetMessageAdd = (data) =>
  fetch.post(Api.GetMessageAdd.url, { ...Api.GetMessageAdd.data, ...data });
//考试题
export const Exam = (data) =>
  fetch.post(Api.Exam.url, { ...Api.Exam.data, ...data });
//个人学习档案
export const StudyStatistics = (data) =>
  fetch.post(Api.StudyStatistics.url, { ...Api.StudyStatistics.data, ...data });
//考试详情
export const ExamDetailListItem = (data) =>
  fetch.post(Api.ExamDetailListItem.url, {
    ...Api.ExamDetailListItem.data,
    ...data,
  });
//考试记录详情
export const ExamReview = (data) =>
  fetch.post(Api.ExamReview.url, { ...Api.ExamReview.data, ...data });
//报名培训班
export const UpdateTrainingStudentup = (data) =>
  fetch.post(Api.UpdateTrainingStudentup.url, {
    ...Api.UpdateTrainingStudentup.data,
    ...data,
  });
//取消报名培训班
export const UpdateTrainingStudentdown = (data) =>
  fetch.post(Api.UpdateTrainingStudentdown.url, {
    ...Api.UpdateTrainingStudentdown.data,
    ...data,
  });
//未读通知
export const UnReadNotice = (data) =>
  fetch.post(Api.UnReadNotice.url, { ...Api.UnReadNotice.data, ...data });
//修改密码
export const UpdatePwd = (data) =>
  fetch.post(Api.UpdatePwd.url, { ...Api.UpdatePwd.data, ...data });
//设置密保--密码验证
export const SetPasswordQuestion = (data) =>
  fetch.post(Api.SetPasswordQuestion.url, {
    ...Api.SetPasswordQuestion.data,
    ...data,
  });
//设置密保--添加密保问题
export const AddPasswordQuestion = (data) =>
  fetch.post(Api.AddPasswordQuestion.url, {
    ...Api.AddPasswordQuestion.data,
    ...data,
  });
//修改信息--获取用户详细信息
export const GetUserInfo = (data) =>
  fetch.post(Api.GetUserInfo.url, { ...Api.GetUserInfo.data, ...data });
//修改信息--获取职位分类
export const GetGradeList = (data) =>
  fetch.post(Api.GetGradeList.url, { ...Api.GetGradeList.data, ...data });
//修改用户信息
export const UpdateUserInfo = (data) =>
  fetch.post(Api.UpdateUserInfo.url, { ...Api.UpdateUserInfo.data, ...data });
//专题培训班--个人学习信息
export const ClassInformation = (data) =>
  fetch.post(Api.ClassInformation.url, {
    ...Api.ClassInformation.data,
    ...data,
  });
//专题培训班--班级详情
export const ClassDetail = (data) =>
  fetch.post(Api.ClassDetail.url, { ...Api.ClassDetail.data, ...data });
//专题培训班--教学计划
export const ClassPlan = (data) =>
  fetch.post(Api.ClassPlan.url, { ...Api.ClassPlan.data, ...data });
//专题培训班--同学名录
export const ClassStudent = (data) =>
  fetch.post(Api.ClassStudent.url, { ...Api.ClassStudent.data, ...data });
//专题培训班--我的论文
export const ClassPaperList = (data) =>
  fetch.post(Api.ClassPaperList.url, { ...Api.ClassPaperList.data, ...data });
//专题培训班--班级相册
export const PhotoAlbumList = (data) =>
  fetch.post(Api.PhotoAlbumList.url, { ...Api.PhotoAlbumList.data, ...data });
//专题培训班--班级话题
export const ClassTopicList = (data) =>
  fetch.post(Api.ClassTopicList.url, { ...Api.ClassTopicList.data, ...data });
//专题培训班--班级课程
export const ClassCourse = (data) =>
  fetch.post(Api.ClassCourse.url, { ...Api.ClassCourse.data, ...data });

export const GetInTraining = (data) =>
  fetch.post(Api.GetInTraining.url + data.TypeId);
//专题培训班--班级公告
export const ClassNoticeList = (data) =>
  fetch.post(Api.ClassNoticeList.url, { ...Api.ClassNoticeList.data, ...data });
//专题培训班--班级考试
export const ClassExam = (data) =>
  fetch.post(Api.ClassExam.url, { ...Api.ClassExam.data, ...data });
//专题培训班--班级文章内容
export const ClassArticleDetail = (data) =>
  fetch.post(Api.ClassArticleDetail.url, {
    ...Api.ClassArticleDetail.data,
    ...data,
  });
//专题培训班--查看用户权限
export const CheckUserClass = (data) =>
  fetch.post(Api.CheckUserClass.url, { ...Api.CheckUserClass.data, ...data });
//专题培训班--添加相册
export const GetPhotoAlbumAdd = (data) =>
  fetch.post(Api.GetPhotoAlbumAdd.url, {
    ...Api.GetPhotoAlbumAdd.data,
    ...data,
  });
//专题培训班--添加相册
export const PhotoAlbumAdd = (data) =>
  fetch.post(Api.PhotoAlbumAdd.url, { ...Api.PhotoAlbumAdd.data, ...data });
//专题培训班--班级照片
export const PhotoPreview = (data) =>
  fetch.post(Api.PhotoPreview.url, { ...Api.PhotoPreview.data, ...data });
//专题培训班--班级照片
export const GetPhotoUpFile = (data) =>
  fetch.post(Api.GetPhotoUpFile.url, { ...Api.GetPhotoUpFile.data, ...data });
//专题培训班--添加话题
export const ClassTopicAdd = (data) =>
  fetch.post(Api.ClassTopicAdd.url, { ...Api.ClassTopicAdd.data, ...data });
//专题培训班--添加论文
export const ClassPaperAdd = (data) =>
  fetch.post(Api.ClassPaperAdd.url, { ...Api.ClassPaperAdd.data, ...data });
//专题培训班--打印证书
export const PrintJY = (data) =>
  fetch.post(Api.PrintJY.url, { ...Api.PrintJY.data, ...data });
//专题培训班--打印学时证明
export const PrintXS = (data) =>
  fetch.post(Api.PrintXS.url, { ...Api.PrintXS.data, ...data });
//原创文章添加（发表心声）
export const AddOriginalArticle = (data) =>
  fetch.post(Api.AddOriginalArticle.url, {
    ...Api.AddOriginalArticle.data,
    ...data,
  });
//原创文章列表(学员心声)
export const OriginalArticleList = (data) =>
  fetch.post(Api.OriginalArticleList.url, {
    ...Api.OriginalArticleList.data,
    ...data,
  });
//专题培训班--获取分类
export const GetTrainingArticleCategory = (data) =>
  fetch.post(Api.GetTrainingArticleCategory.url, {
    ...Api.GetTrainingArticleCategory.data,
    ...data,
  });
//专题培训班--发布文章
export const ClassPublishArticle = (data) =>
  fetch.post(Api.ClassPublishArticle.url, {
    ...Api.ClassPublishArticle.data,
    ...data,
  });
//考试中心--提交考试  问卷提交
export const PostExam = (data) =>
  fetch.post(Api.PostExam.url, { ...Api.PostExam.data, ...data });
//邮箱找回密码
export const GetPasswordEmail = (data) =>
  fetch.post(Api.GetPasswordEmail.url, {
    ...Api.GetPasswordEmail.data,
    ...data,
  });
//获取密保
export const GetQuestion = (data) =>
  fetch.post(Api.GetQuestion.url, { ...Api.GetQuestion.data, ...data });
//注册
export const Register = (data) =>
  fetch.post(Api.Register.url, { ...Api.Register.data, ...data });
//获取单位（注册）
export const GetGroupList = (data) =>
  fetch.post(Api.GetGroupList.url, { ...Api.GetGroupList.data, ...data });
//获取行业类别（注册）
export const GetProfessionType = (data) =>
  fetch.get(Api.GetProfessionType.url, {
    ...Api.GetProfessionType.data,
    ...data,
  });
//发送验证码
export const SendMsg = (data) =>
  fetch.post(Api.SendMsg.url, { ...Api.SendMsg.data, ...data });
//提交问题
export const GetPasswordByQuestion = (data) =>
  fetch.post(Api.GetPasswordByQuestion.url, {
    ...Api.GetPasswordByQuestion.data,
    ...data,
  });
//提示未读通知
export const UnReadNotice2 = (data) =>
  fetch.post(Api.UnReadNotice2.url, { ...Api.UnReadNotice2.data, ...data });
//播放信息
export const Play = (data) =>
  fetch.post(Api.Play.url, { ...Api.Play.data, ...data });
//试看播放信息
export const TryPlay = (data) =>
  fetch.post(Api.TryPlay.url, { ...Api.TryPlay.data, ...data });
//刷新播放进度
export const Refresh = (data) =>
  fetch.post(Api.Refresh.url, { ...Api.Refresh.data, ...data });
//添加评论
export const CourseCommentAdd = (data) =>
  fetch.post(Api.CourseCommentAdd.url, {
    ...Api.CourseCommentAdd.data,
    ...data,
  });
//获取评论
export const CourseComment = (data) =>
  fetch.post(Api.CourseComment.url, { ...Api.CourseComment.data, ...data });
//获取评论
//播放前调用
export const ClearPlayingCourse = (data) =>
  fetch.post(Api.ClearPlayingCourse.url, {
    ...Api.ClearPlayingCourse.data,
    ...data,
  });
//播放plYjwplay
export const PlayJwplay = (data) =>
  fetch.post(Api.PlayJwplay.url, { ...Api.PlayJwplay.data, ...data });
//单视频播放进度
export const SingleProcess = (data) =>
  fetch.post(Api.SingleProcess.url, { ...Api.SingleProcess.data, ...data });
//播放精英视频
export const PlayJY = (data) =>
  fetch.post(Api.PlayJY.url, { ...Api.PlayJY.data, ...data });
//播放Scorm视频
export const PlayScorm = (data) =>
  fetch.post(Api.PlayScorm.url, { ...Api.PlayScorm.data, ...data });
//播放Scorm视频
export const PlayJyScorm = (data) =>
  fetch.post(Api.PlayJyScorm.url, { ...Api.PlayJyScorm.data, ...data });
//播放Single视频
export const PlaySingle = (data) =>
  fetch.post(Api.PlaySingle.url, { ...Api.PlaySingle.data, ...data });
//播放PlayOffice视频
export const PlayOffice = (data) =>
  fetch.post(Api.PlayOffice.url, { ...Api.PlayOffice.data, ...data });
//PlayOffice视频进度提交
export const ProcessOffice = (data) =>
  fetch.post(Api.ProcessOffice.url, { ...Api.ProcessOffice.data, ...data });
//图书分类
export const BookCategory = (data) =>
  fetch.post(Api.BookCategory.url, { ...Api.BookCategory.data, ...data });
//图书分类搜索
export const BookList = (data) =>
  fetch.post(Api.BookList.url, { ...Api.BookList.data, ...data });
//图书详情内容
export const BookContent = (data) =>
  fetch.post(Api.BookContent.url, { ...Api.BookContent.data, ...data });
//图书章节
export const BookChapterList = (data) =>
  fetch.post(Api.BookChapterList.url, { ...Api.BookChapterList.data, ...data });
//图书章节内容
export const BookChapterContent = (data) =>
  fetch.post(Api.BookChapterContent.url, {
    ...Api.BookChapterContent.data,
    ...data,
  });
//添加图书评论
export const BookCommentAdd = (data) =>
  fetch.post(Api.BookCommentAdd.url, { ...Api.BookCommentAdd.data, ...data });
//获取图书评论
export const BookCommentList = (data) =>
  fetch.post(Api.BookCommentList.url, { ...Api.BookCommentList.data, ...data });
//学院概况
export const CollegeInfo = (data) =>
  fetch.post(Api.CollegeInfo.url, { ...Api.CollegeInfo.data, ...data });
//学员风采
export const StudentStyle = (data) =>
  fetch.post(Api.StudentStyle.url, { ...Api.StudentStyle.data, ...data });
//查看购物车
export const GetMyShoppingCart = (data) =>
  fetch.post(Api.GetMyShoppingCart.url, {
    ...Api.GetMyShoppingCart.data,
    ...data,
  });
//添加课程到购物车
export const AddToCart = (data) =>
  fetch.post(Api.AddToCart.url, { ...Api.AddToCart.data, ...data });
//购物车中删除
export const DelFromCart = (data) =>
  fetch.post(Api.DelFromCart.url, { ...Api.DelFromCart.data, ...data });
//生成订单
export const AddOrder = (data) =>
  fetch.post(Api.AddOrder.url, { ...Api.AddOrder.data, ...data });
//删除订单
export const DelOrder = (data) =>
  fetch.post(Api.DelOrder.url, { ...Api.DelOrder.data, ...data });
//取消订单
export const CancelOrder = (data) =>
  fetch.post(Api.CancelOrder.url, { ...Api.CancelOrder.data, ...data });
//再次购买
export const RestoreOrder = (data) =>
  fetch.post(Api.RestoreOrder.url, { ...Api.RestoreOrder.data, ...data });
//订单列表
export const GetMyOrder = (data) =>
  fetch.post(Api.GetMyOrder.url, { ...Api.GetMyOrder.data, ...data });
//订单详细
export const GetOrderDetail = (data) =>
  fetch.post(Api.GetOrderDetail.url, { ...Api.GetOrderDetail.data, ...data });
//确认支付
export const BeginPay = (data) =>
  fetch.post(Api.BeginPay.url, { ...Api.BeginPay.data, ...data });
// 完成支付

export const EndPay = (data) =>
  fetch.post(Api.EndPay.url, { ...Api.EndPay.data, ...data });
export const EndPay2 = (data) =>
  fetch.post(Api.EndPay2.url, { ...Api.EndPay2.data, ...data });
export const EndPay3 = (data) =>
  fetch.post(Api.EndPay3.url, { ...Api.EndPay3.data, ...data });
//确认支付（用于生成票据）
export const InvoiceBeginPay = (data) =>
  fetch.post(Api.InvoiceBeginPay.url, { ...data });
//获取票据
export const GetInvoice = (data) =>
  fetch.post(Api.GetInvoice.url, { ...Api.GetInvoice.data, ...data });
//最终确认支付
export const InvoiceFinishPay = (data) =>
  fetch.post(Api.InvoiceFinishPay.url, {
    ...Api.InvoiceFinishPay.data,
    ...data,
  });
//支付宝支付接口
export const PayForInvoice = (data) =>
  fetch.post(Api.PayForInvoice.url, { ...Api.PayForInvoice.data, ...data });
//添加班级说说
export const AddTrainingSay = (data) =>
  fetch.post(Api.AddTrainingSay.url, { ...Api.AddTrainingSay.data, ...data });
//删除班级说说
export const DelTrainingSay = (data) =>
  fetch.post(Api.DelTrainingSay.url, { ...Api.DelTrainingSay.data, ...data });
//获取班级说说列表
export const TrainingSayList = (data) =>
  fetch.post(Api.TrainingSayList.url, { ...Api.TrainingSayList.data, ...data });
//判断用户是否存在
export const CheckAccountExit = (data) =>
  fetch.post(Api.CheckAccountExit.url, {
    ...Api.CheckAccountExit.data,
    ...data,
  });
//发送验证码
export const sendMsg = (data) =>
  fetch.post(Api.sendMsg.url, { ...Api.sendMsg.data, ...data });
//提交验证码
export const CheckValidateCode = (data) =>
  fetch.post(Api.CheckValidateCode.url, {
    ...Api.CheckValidateCode.data,
    ...data,
  });
//忘记密码 找回密码
export const ForgetToUpdatePwd = (data) =>
  fetch.post(Api.ForgetToUpdatePwd.url, {
    ...Api.ForgetToUpdatePwd.data,
    ...data,
  });
//获取首页轮播图
export const GetLink = (data) =>
  fetch.post(Api.GetLink.url, { ...Api.GetLink.data, ...data });
//人脸验证
export const CheckFace = (data) =>
  fetch.post(Api.CheckFace.url, { ...Api.CheckFace.data, ...data });
// 点击付款
export const PcPayOrder = (data) =>
  fetch.post(Api.PcPayOrder.url, { ...Api.PcPayOrder.data, ...data });
//   // 获取登录注册验证码
// export const GetLoginVC = (data) => fetch.post(Api.GetLoginVC.url,
//   { ...Api.GetLoginVC.data, ...data })
// 跳转百万信息系统
export const LoginTrainingImfoSystem = (data) =>
  fetch.post(Api.LoginTrainingImfoSystem.url, {
    ...Api.LoginTrainingImfoSystem.data,
    ...data,
  });
export const ActivityProgressCreate = (data) =>
  fetch.post(Api.ActivityProgressCreate.url, {
    ...Api.ActivityProgressCreate.data,
    ...data,
  });
export const ActivityProgressDetail = (data) =>
  fetch.post(Api.ActivityProgressDetail.url, {
    ...Api.ActivityProgressDetail.data,
    ...data,
  });
// GetMachine

export const GetMachine = (data) =>
  fetch.post(Api.GetMachine.url, { ...Api.GetMachine.data, ...data });
// 获取首页考试模块
export const GetExamType = (data) =>
  fetch.get(Api.GetExamType.url, { ...Api.GetExamType.data, ...data });
// 获取首验证码
export const RegisterSendMsg = (data) =>
  fetch.post(Api.RegisterSendMsg.url, { ...Api.RegisterSendMsg.data, ...data });
//上传附件
export const ToLead = (data) => {
  return fetch.postFormData(Api.ToLead.url, data);
};
//获取上传的列表
export const TrainingUserImportList = (data) =>
  fetch.post(
    Api.TrainingUserImportList.url +
      "?file=" +
      data.file +
      "&page=" +
      data.page +
      "&rows=" +
      data.rows
  );
// 集体报名提交
export const TrainingUserSaveImportAsyncNew = (data) =>
  fetch.post(
    Api.TrainingUserSaveImportAsyncNew.url + "?trainingId=" + data.trainingId,
    data
  );
export const ReportTrainingUserStudyDetail = (data) =>
  fetch.post(Api.ReportTrainingUserStudyDetail.url + "?id=" + data.id);

//上传身份获取信息
export const GetIdCardDetail = (data) => {
  return fetch.post(Api.GetIdCardDetail.url, {
    ...Api.GetIdCardDetail.data,
    ...data,
  });
};
// 获取报名信息
export const TrainingUserDetail = (data) => {
  return fetch.post(
    Api.TrainingUserDetail.url +
      data.trainingid +
      "&userid=" +
      data.userid +
      "&token=" +
      data.token
  );
};
// 获取审核信息
export const TrainingUserAuditDetail = (data) => {
  return fetch.post(
    Api.TrainingUserAuditDetail.url +
      data.trainingid +
      "&userid=" +
      data.userid +
      "&sort=" +
      data.sort +
      "&OrderNo=" +
      data.OrderNo
  );
};

//是否可以支付
export const CheckPay = (data) => {
  return fetch.post(Api.CheckPay.url + data.orderId);
};
// 审核
export const TrainingUserpPrsonalAudit = (data) => {
  return fetch.post(Api.TrainingUserpPrsonalAudit.url, {
    ...Api.TrainingUserpPrsonalAudit.data,
    ...data,
  });
};
//获取订单报名人列表

export const GetTrainingUser = (data) => {
  return fetch.post(Api.GetTrainingUser.url, {
    ...Api.GetTrainingUser.data,
    ...data,
  });
};
//微信支付
export const WeChatPay = (data) => {
  return fetch.post(Api.WeChatPay.url + data.orderId);
};
// 获取订单支付状态
export const CheckPayStatus = (data) => {
  return fetch.post(Api.CheckPayStatus.url + data.orderId);
};
//我的证书列表
export const TrainingUserPrintList = (data) => {
  return fetch.post(Api.TrainingUserPrintList.url, {
    ...Api.TrainingUserPrintList.data,
    ...data,
  });
};
//时间内的人数
export const GetCheckTimeUserCount = (data) => {
  return fetch.post(Api.GetCheckTimeUserCount.url, {
    ...Api.GetCheckTimeUserCount.data,
    ...data,
  });
};
//获取后台报名表列表
export const TrainingUserDetailList = (data) => {
  return fetch.post(
    Api.TrainingUserDetailList.url +
      data.trainingid +
      "&orderid=" +
      data.orderid
  );
};

export const PrintJYAllByTraining = (data) =>
  fetch.get(Api.PrintJYAllByTraining.url, { ...Api.PrintJYAllByTraining.data, ...data });

/* 将所有的API封装起来 */
const allApi = {};
for (let key in Api) {
  if (Api[key].method === "get") {
    if (key === "GetNoticeInfoContent") {
      allApi[key] = (data) =>
        fetch.get(Api[key].url + "/" + data.Id, { ...Api[key].data, ...data });
    } else {
      allApi[key] = (data) =>
        fetch.get(Api[key].url, { ...Api[key].data, ...data });
    }
  } else if (Api[key].method === "formData") {
    allApi[key] = (data, config) =>
      fetch.postFormData(Api[key].url, data, config);
  } else {
    allApi[key] = (data) =>
      fetch.post(Api[key].url, { ...Api[key].data, ...data });
  }
}
export default allApi;

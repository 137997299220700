<template>
  <div>
    <div
      class="no-height"
      :class="{ 'have-height': currentPath != '/home' && currentPath != '/' }"
      :style="{ height: +cssNum * 80 + 'px' }"
      v-if="showHeader"
    ></div>
    <div
      class="header"
      v-if="showHeader"
      :class="{
        backgroundNavBar:
          top >= 400 || (currentPath != '/home' && currentPath != '/'),
      }"
      :style="{ height: +cssNum * 80 + 'px' }"
    >
      <div class="navigationBar" :style="{ height: +cssNum * 80 + 'px' }">
        <div
          class="navigationBox"
          :style="{ transform: 'scale(' + cssNum + ')' }"
        >
          <img
            src="https://aqsc.jystudy.com/HZImage/pc/images/LOGO.png"
            class="logo"
          />
          <div class="barList">
            <div
              v-for="(item, index) in barList"
              :key="index"
              class="bars"
              @click="$router.push(item.router)"
            >
              <div
                class="barsName"
                :class="{
                  barsNameBg:
                    top >= 400 ||
                    (currentPath != '/home' && currentPath != '/'),
                  isActive: currentPath == item.router,
                  isActive2:
                    currentPath == item.router &&
                    (top >= 400 ||
                      (currentPath != '/home' && currentPath != '/')),
                }"
              >
                <img
                  src="https://aqsc.jystudy.com/HZImage/pc/images/safetymonth.png"
                  alt=""
                  class="safetymonth"
                  v-if="index == 1"
                />
                {{ index != 1 ? item.name : "" }}
                <!-- <div class="shadow" v-if="currentPath == item.router"></div> -->
              </div>
            </div>
          </div>
          <div class="loginBox">
            <div
              class="searchBox"
              @click="$router.push({ path: '/searchList' })"
            ></div>
            <div class="follow">
              <img
                src="https://aqsc.jystudy.com/HZImage/pc/images/shouji.png"
                alt=""
              />
              <p>扫码关注</p>
              <div id="tokenBox" v-show="false" v-html="tokenBox"></div>
            </div>
            <div class="loginBtn" @click="loginShow" v-if="!userMessage.Name">
              <img
                src="https://aqsc.jystudy.com/HZImage/pc/images/touxiang.png"
                alt=""
              />
              <p>立即登录</p>
            </div>
            <div class="loginBtn" v-if="userMessage.Name">
              <img
                src="https://aqsc.jystudy.com/HZImage/pc/images/touxiang.png"
                alt=""
              />
              <p>{{ userMessage.Name }}</p>
              <div class="dialog">
                <!-- 个人信息查看弹框 -->
                <div class="dialogBox">
                  <div class="userInfo">
                    <div class="imgAndName">
                      <img
                        class="userImg"
                        :src="'https://aqsc.jystudy.com/Webroot/images/noGoodStudent.png'"
                        alt=""
                      />
                      <div class="">{{ RoleName }}({{ account }})</div>
                      <div>欢迎访问页面！</div>
                    </div>
                    <div class="logOut" @click="quitBtn">退出登录</div>
                  </div>
                  <div class="userOperation">
                    <!-- <div>
                      <img
                        src="https://pic.imgdb.cn/item/625e5964239250f7c54178cd.png"
                        alt=""
                      />
                      <span
                        @click="
                          toPersonalCenter('/personalCenter/personalArchives')
                        "
                        >个人学习档案</span
                      >
                    </div> -->
                    <div>
                      <img
                        src="https://pic.imgdb.cn/item/625e59fa239250f7c5438ee8.png"
                        alt=""
                      />
                      <span @click="toPersonalCenter('/pwdEdit')"
                        >修改密码</span
                      >
                    </div>
                  </div>
                  <div class="operationBtn">
                    <el-button
                      class="btn"
                      @click="toPersonalCenter('/myCourseList')"
                      type="primary"
                      >个人中心</el-button
                    >
                    <el-button
                      class="btn"
                      type="warning"
                      v-if="Role == '管理员'"
                      ><a
                        style="color: #fff !important"
                        href="/admin"
                        target="_blank"
                        >进入管理平台</a
                      ></el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 个人信息查看弹框 -->
    <div class="login">
      <el-dialog
        :visible.sync="isHaveLogin"
        width="940px"
        :show-close="false"
        append-to-body
        center
        :before-close="handleClose"
      >
        <div class="login-box">
          <img
            class="login-box-left"
            src="https://aqsc.jystudy.com/HZImage/pc/images/图层 6.png"
            alt=""
          />
          <div class="login-box-right">
            <div class="login-title">
              {{ isRegister ? "用户注册" : "用户登录" }}
            </div>
            <div class="login-choose" v-if="!isRegister">
              <div
                class="item"
                @click="changeUsePwd(false)"
                :class="{ 'item-active': !isUsePwd }"
              >
                快捷登录
              </div>
              <div class="line"></div>
              <div
                class="item"
                @click="changeUsePwd(true)"
                :class="{ 'item-active': isUsePwd }"
              >
                密码登录
              </div>
            </div>
            <el-form
              :model="ruleForm"
              :rules="isRegister ? rules2 : !isUsePwd ? rules : rules1"
              ref="ruleForm"
            >
              <el-form-item prop="name" v-if="isRegister">
                <el-input placeholder="请输入姓名" v-model="ruleForm.name">
                  <i
                    slot="prefix"
                    class="phone-label el-icon-s-custom"
                    alt=""
                  />
                </el-input>
              </el-form-item>
              <el-form-item prop="GroupId" v-if="isRegister">
                <div class="groupPosition">
                  <el-cascader
                    :props="cascaderprops"
                    clearable
                    placeholder="请选择所属区县"
                    class="cascader"
                    :show-all-levels="false"
                    v-model="ruleForm.GroupId"
                  >
                  </el-cascader>
                  <i class="el-icon-s-custom icons" />
                </div>
              </el-form-item>
              <el-form-item prop="account">
                <el-input
                  placeholder="请输入手机号码"
                  v-model="ruleForm.account"
                >
                  <img
                    slot="prefix"
                    src="https://aqsc.jystudy.com/HZImage/pc/images/下载 (1).png"
                    class="phone-label"
                    alt=""
                  />
                </el-input>
              </el-form-item>
              <el-form-item :prop="!isRegister && !isUsePwd ? '' : 'password'">
                <el-input
                  :placeholder="
                    isRegister
                      ? '请输入短信验证码'
                      : isUsePwd
                      ? '请输入密码'
                      : '请输入短信验证码'
                  "
                  :type="!isRegister && isUsePwd ? 'password' : 'text'"
                  v-model="ruleForm.password"
                  auto-complete="new-password"
                >
                  <img
                    v-if="isUsePwd && !isRegister"
                    slot="prefix"
                    src="https://aqsc.jystudy.com/HZImage/pc/images/pwdpic.png"
                    class="phone-label"
                    alt=""
                  />
                  <img
                    v-if="!isUsePwd || isRegister"
                    slot="prefix"
                    src="https://aqsc.jystudy.com/HZImage/pc/images/下载 (2).png"
                    class="phone-label"
                    alt=""
                  />
                  <el-button
                    slot="append"
                    v-if="!isUsePwd || isRegister"
                    @click="toSend"
                    >{{ time == 0 ? "获取验证码" : time + "s" }}</el-button
                  >
                </el-input>
              </el-form-item>

              <el-form-item prop="code" v-if="isUsePwd && !isRegister">
                <el-input placeholder="请输入验证码" v-model="ruleForm.code">
                  <div slot="append" class="vc-pic-box">
                    <img :src="src" alt="" @click="getLoginVC()" />
                  </div>
                </el-input>
              </el-form-item>
              <el-form-item v-if="isRegister" prop="newPassword">
                <el-input
                  placeholder="请设置登录密码"
                  v-model="ruleForm.newPassword"
                >
                  <img
                    slot="prefix"
                    src="https://aqsc.jystudy.com/HZImage/pc/images/pwdpic.png"
                    class="phone-label"
                    alt=""
                  />
                </el-input>
              </el-form-item>
              <div class="text" v-if="!isUsePwd && !isRegister">
                <div class="login-choose2">
                  <div class="item" @click="toRegister">免费注册</div>
                  <div class="line"></div>
                  <div class="item" @click="toForget">忘记密码</div>
                </div>
              </div>
              <!-- <div
                class="text"
                style="justify-content: left; margin-bottom: 74px"
                v-if="!isUsePwd && !isRegister"
              >
                <img
                  src="https://aqsc.jystudy.com/HZImage/pc/images/-zhuyi.png"
                  alt=""
                />未注册的手机号验证后将自动创建新账号
              </div> -->
              <div
                class="text"
                style="
                  justify-content: left;
                  margin-bottom: 15px;
                  align-items: flex-start;
                "
                v-if="isRegister"
              >
                <img
                  src="https://aqsc.jystudy.com/HZImage/pc/images/-zhuyi.png"
                  alt=""
                />
                <div>密码必须为8 ~16个字符，其中大写字母、小写字母、数字</div>
              </div>
              <div class="text" v-if="isUsePwd && !isRegister">
                <div class="user-look user-look1" v-if="!isRegister">
                  <el-checkbox v-model="remember">记住密码</el-checkbox>
                </div>
              </div>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">{{
                  isRegister ? "立即注册" : "立即登录"
                }}</el-button>
              </el-form-item>
              <div class="user-look user-look2" v-if="isRegister">
                已有账号？<span @click="toRegister">去登录</span>
              </div>
              <!-- <div class="user-look">
                <el-checkbox v-model="checked"></el-checkbox>
                我已阅读并同意 <span>《用户协议》</span> 和<span
                  >《隐私条款》</span
                >
              </div> -->
            </el-form>
          </div>
          <img
            class="login-box-cancel"
            src="https://aqsc.jystudy.com/HZImage/pc/images/guanbi.png"
            alt=""
            @click="closeLogin()"
          />
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
import { mapActions, mapState } from "vuex";
import { getStore, setStore } from "@/plugins/utils";
import { Message } from "element-ui";
import {
  GetVerifyCode,
  Register,
  LoginShort,
  LoginOut,
  RegisterSendMsg,
  LoginCode,
  AntiForgeryToken,
  GetGroupList,
} from "@/service/getData";
export default {
  name: "Header",
  data() {
    var validatorGroupId = function (rule, value, callback) {
      if (!value) {
        callback(new Error("请选择所属区县"));
      } else {
        callback();
      }
    };
    var validatorPassword = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(value)) {
        callback(new Error("密码格式为8-16位大小写字母加数字"));
      } else {
        callback();
      }
    };
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(
          value
        )
      ) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };

    return {
      cascaderprops: {
        lazy: true,
        lazyLoad: this.lazyLoad,
        emitPath: false,
        checkStrictly: true,
      },
      top: 0,
      src: "",
      currentPath: "",
      barList: [
        {
          name: "首页",
          router: "/home",
        },
        {
          name: "安全生产月",
          router: "/safetyTopic",
        },
        {
          name: "培训信息",
          router: "/trainList",
        },
        {
          name: "企业网络学院",
        },
        {
          name: "法律法规",
          router: "/downloadList",
        },
        {
          name: "通知公告",
          router: "/messageList",
        },
        {
          name: "关于我们",
          router: "/aboutUs",
        },
      ],
      userInfoVisible: true,
      RoleName: "",
      account: "",
      Role: "",
      isHaveLogin: false,
      isRegister: false,
      ruleForm: {
        name: "",
        account: "",
        password: "",
        newPassword: "",
        code: "",
        GroupId: "",
      },
      checked: true,
      isUsePwd: false,
      remember: true,
      cssNum: 1.0,
      timer: null, // 定时器名称
      time: 0,
      rules: {
        account: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      rules1: {
        account: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        password: [
          { required: true, validator: validatorPassword, trigger: "change" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rules2: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        account: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        GroupId: [
          { required: true, validator: validatorGroupId, trigger: "change" },
        ],
      },
      userAllMessage: {},
      userMessage: {},
      tokenBox: "",
      address: "",
    };
  },
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    isSkip: {
      default: true,
      type: Boolean,
    },
  },
  created() {
    this.keyupSubmit();
  },
  mounted() {
    this.changeCss();
    this.getLoginVC();
    window.addEventListener("scroll", this.handleScrollScroll, true);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  computed: {
    ...mapState(["userAgent"]),
  },
  methods: {
    ...mapActions(["getUserAgent", "getUserInformation", "saveUserInfo"]),
    lazyLoad(node, resolve) {
      this.getData(node, resolve);
    },
    async getData(node, resolve) {
      let level = node.level;
      if (!node.data) {
        let res = await GetGroupList();
        const nodes = Array.from(res).map((item) => ({
          value: item.id,
          label: item.text,
          leaf: level >= 1,
        }));
        resolve(nodes);
      } else {
        let res = await GetGroupList({
          id: node.data.value,
        });
        const nodes = Array.from(res).map((item) => ({
          value: item.id,
          label: item.text,
          leaf: level >= 1,
        }));
        resolve(nodes);
      }
    },
    loginShow() {
      this.isHaveLogin = true;
      this.isRegister = false;
      this.isUsePwd = false;
      this.$nextTick(function () {
        this.$refs["ruleForm"].clearValidate();
      });
      for (let key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
    },
    //  获取验证码
    changeCss() {
      let windowWidth = window.innerWidth;
      if (windowWidth < 1560) {
        this.cssNum = (windowWidth / 1903).toFixed(2);
      } else {
        this.cssNum = 1.0;
      }
      window.onresize = () => {
        return (() => {
          windowWidth = window.innerWidth;
          if (windowWidth < 1560) {
            this.cssNum = (windowWidth / 1903).toFixed(2);
          } else {
            this.cssNum = 1.0;
          }
        })();
      };
    },
    async getLoginVC() {
      let res = await GetVerifyCode();
      if (res.IsSuccess) {
        this.src = res.Data.Img;
      }
    },
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.submitForm("ruleForm");
        }
      };
    },
    async closeLogin() {
      if (
        this.currentPath == "/home" ||
        this.currentPath == "/forget" ||
        this.currentPath == "/messageShow"
      ) {
        this.isHaveLogin = false;
      } else {
        let res = await LoginShort();
        this.userMessage = res.Data.Model;
        this.userAllMessage = res.Data;
        if (this.userMessage.Name) {
          this.isHaveLogin = false;
        } else {
          this.$router.push({
            path: "/home",
          });
        }
      }
      // this.isHaveLogin = false;
    },
    async quitBtn() {
      const res = await LoginOut();
      localStorage.removeItem("modifyPassword");
      if (this.currentPath == "/home") {
        this.$router.go(0);
      } else {
        this.$router.push("/home");
      }
    },
    toRegister() {
      this.isRegister = !this.isRegister;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        if (this.isUsePwd && !this.isRegister) {
          this.ruleForm.account = localStorage.getItem("account");
          this.ruleForm.password = localStorage.getItem("passWord");
          this.remember = getStore("remember");
        } else {
          this.ruleForm.account = "";
          this.ruleForm.password = "";
        }
      });
    },
    toForget() {
      this.isHaveLogin = false;
      this.$router.push({ path: "/forget" });
    },
    changeUsePwd(res) {
      this.isUsePwd = res;
      this.ruleForm.password = "";
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        if (this.isUsePwd && !this.isRegister) {
          this.ruleForm.account = localStorage.getItem("account");
          this.ruleForm.password = localStorage.getItem("passWord");
          this.remember = getStore("remember");
        } else {
          this.ruleForm.account = "";
          this.ruleForm.password = "";
        }
      });
    },
    async toSend() {
      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(
          this.ruleForm.account
        )
      ) {
        this.$message.warning("手机号格式错误！");
        return;
      }
      if (this.time == 0) {
        if (this.ruleForm.account != "") {

          
          let DatePice = (new Date()).getTime()
          let Key = '' + this.ruleForm.account + DatePice + 'CqSendMsg'
          let data = await RegisterSendMsg({
            MobileNo: this.ruleForm.account,
            DatePice: DatePice,
            Key: md5(Key).toUpperCase()
          })

          // let data = await RegisterSendMsg({ MobileNo: this.ruleForm.account });
          Message(data.Message);
          clearInterval(this.timer);
          this.timer = null;
          this.time = 60;
          this.timer = setInterval(() => {
            this.time--;
            if (this.time == 0) {
              clearInterval(this.timer);
              this.timer = null;
            }
            // 定时器操作
          }, 1000);
        } else {
          Message("请输入手机号码");
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.checked) {
            // this.$alert("请先同意《用户协议》和《隐私条款》", "提示", {
            //   confirmButtonText: "确定",
            //   showClose: false,
            //   callback: (action) => {},
            // });
            this.$message.warning("请先阅读并同意《用户协议》和《隐私条款》");
          } else {
            if (this.isRegister) {
              let params = {
                account: this.ruleForm.account,
                name: this.ruleForm.name,
                password: this.ruleForm.newPassword,
                smgcode: this.ruleForm.password,
                GroupId: this.ruleForm.GroupId,
              };
              let res = await Register(params);
              if (res.Type == 1) {
                Message.success(res.Message);
                this.isRegister = false;
                clearInterval(this.timer);
                this.timer = null;
              } else {
                Message(res.Message);
              }
            } else if (this.isUsePwd) {
              let params = {
                Account: this.ruleForm.account,
                PassWord: this.ruleForm.password,
                RememberMe: this.remember,
                ValidateCode: this.ruleForm.code,
              };
              let res = await AntiForgeryToken();
              this.tokenBox = res.html;
              setTimeout(async () => {
                let tokenBoxData = {
                  __RequestVerificationToken: document.getElementsByName(
                    "__RequestVerificationToken"
                  )[0].value,
                };
                let data = await LoginCode(Object.assign(params, tokenBoxData));
                if (data.Type == 1) {
                  if (this.remember) {
                    this.getUserInformation();
                    localStorage.setItem("account", this.ruleForm.account);
                    localStorage.setItem("passWord", this.ruleForm.password);
                    setStore("remember", true);
                  } else {
                    localStorage.setItem("account", "");
                    localStorage.setItem("passWord", "");
                    setStore("remember", true);
                  }
                  this.$router.go(0);
                } else if (data.Type == 2) {
                  this.$message.warning(data.Message);
                  if (this.remember) {
                    this.getUserInformation();
                    localStorage.setItem("account", this.ruleForm.account);
                    localStorage.setItem("passWord", this.ruleForm.password);
                    setStore("remember", true);
                  } else {
                    localStorage.setItem("account", "");
                    localStorage.setItem("passWord", "");
                    setStore("remember", true);
                  }
                  localStorage.setItem("modifyPassword", true);
                  this.$router.push("/pwdEdit");
                } else if (data.Type == 3) {
                  if (window.confirm("帐号在别的地方登录，是否踢出？")) {
                    this.kickOut(data.Message);
                    return true;
                  } else {
                    return false;
                  }
                } else if (data.Type == 4) {
                  Message("此电脑已经有用户登录，您不能用其他帐号再次登录！");
                } else if (data.Type == 5) {
                  Message("平台当前在线人数到达上限，请稍后再试！");
                } else if (data.Type == 6) {
                  Message(data.Message);
                } else if (data.Type == 11) {
                  Message(data.Message);
                } else if (data.Type == 12 || data.Type == 13) {
                  Message(data.Message);
                } else if (data.Type == 14) {
                  this.$confirm(data.Message + ",请修改密码", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                    .then(() => {
                      localStorage.setItem("modifyPassword", true);
                      this.$router.replace({
                        path: "/pwdEdit",
                      });
                    })
                    .catch(() => {
                      // 点击取消的操作
                      if (this.remember) {
                        this.getUserInformation();
                        localStorage.setItem("account", this.ruleForm.account);
                        localStorage.setItem(
                          "passWord",
                          this.ruleForm.password
                        );
                        setStore("remember", true);
                      } else {
                        localStorage.setItem("account", "");
                        localStorage.setItem("passWord", "");
                        setStore("remember", true);
                      }
                      localStorage.setItem("modifyPassword", true);
                      this.$router.push("/pwdEdit");
                    });
                } else if (data.Type == 15) {
                  this.$message.warning(data.Message);
                  if (this.remember) {
                    this.getUserInformation();
                    localStorage.setItem("account", this.ruleForm.account);
                    localStorage.setItem("passWord", this.ruleForm.password);
                    setStore("remember", true);
                  } else {
                    localStorage.setItem("account", "");
                    localStorage.setItem("passWord", "");
                    setStore("remember", true);
                  }
                  localStorage.setItem("modifyPassword", true);
                  this.$router.push("/pwdEdit");
                } else {
                  Message(data.Message);
                }
              }, 0);
            } else {
              let params = {
                Mobile: this.ruleForm.account,
                SmgCode: this.ruleForm.password,
              };
              let data = await LoginCode(params);
              if (data.Type == 1) {
                this.$router.go(0);
              } else if (data.Type == 3) {
                if (window.confirm("帐号在别的地方登录，是否踢出？")) {
                  this.kickOut(data.Message);
                  return true;
                } else {
                  return false;
                }
              } else if (data.Type == 4) {
                Message("此电脑已经有用户登录，您不能用其他帐号再次登录！");
              } else if (data.Type == 5) {
                Message("平台当前在线人数到达上限，请稍后再试！");
              } else if (data.Type == 6) {
                Message(data.Message);
              } else if (data.Type == 11) {
                Message(data.Message);
              } else if (data.Type == 12 || data.Type == 13) {
                Message(data.Message);
              } else if (data.Type == 14) {
                this.$confirm(data.Message + ",请修改密码", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.$router.replace({
                      path: "/pwdEdit",
                    });
                  })
                  .catch(() => {
                    localStorage.setItem("account", "");
                    localStorage.setItem("passWord", "");
                    setStore("remember", true);
                    this.$router.go(0);
                  });
              } else if (data.Type == 15) {
                Message(data.Message);
                this.$router.go(0);
              } else {
                Message(data.Message);
              }
            }
            setTimeout(() => {
              this.getLoginVC();
            }, 1000);
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async handleClose(done) {
      if (this.currentPath == "/home") {
      } else {
        let res = await LoginShort();
        this.userMessage = res.Data.Model;
        this.userAllMessage = res.Data;
        if (this.userMessage.Name) {
          this.isHaveLogin = false;
        } else {
          this.$router.push({
            path: "/home",
          });
        }
      }
      done();
    },

    async getLoginShort() {
      const short = await LoginShort();
      this.userMessage = short.Data.Model;
      this.userAllMessage = short.Data;
      if (this.isSkip) {
        this.isHaveLogin = false;
        // if (!this.userMessage.UserFace) {
        //   commonService.alertMs("<div style='float:left;width:230px;line-height:100px;font-weight:blod;font-size:18px'>请前往移动端进行刷脸验证</div><div style='float:right;'><img style='width:150px;height:150px' src='/images/appImg.png'><p style='width:150px;text-align:center'>请用手机微信或手机浏览器扫一扫下载APP</p></div>");
        // }
      } else if (this.userMessage.Name) {
        this.isHaveLogin = false;
      } else {
        this.isHaveLogin = true;
      }
      if (short.Data.Model.Name !== "" && short.Data.Model.Name.length !== 0) {
        this.RoleName = short.Data.Model.Name;
        this.account = short.Data.Model.Account;
        this.Role = short.Data.UserType;
        this.login = false;
        localStorage.setItem("UserType", short.Data.UserType);
      } else {
        this.login = true;
      }
    },
    // 跳转到个人中心页面
    toPersonalCenter(path) {
      this.$router.push({ path });
    },
    handleScrollScroll() {
      this.$nextTick(() => {
        this.top =
          document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageYOffset;
      });
    },
  },
  watch: {
    $route(to, from) {
      this.getLoginShort();
      this.currentPath = to.path;
      this.changeCss();
    },
  },
};
</script>
<style scoped lang="less">
.header {
  width: 100%;
  min-width: 1200px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  .navigationBar {
    width: 100%;
    position: relative;

    .navigationBox {
      width: 1560px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -40px 0 0 -780px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // transition: all 0.3s ease;
      .logo {
        width: 398px;
        height: 53px;
      }
      .barList {
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        display: flex;
        align-items: center;
        .bars:nth-child(1) {
          margin-right: 0;
        }
        .bars {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 15px;
          > img {
            width: 120px;
            height: 38px;
            margin-left: 10px;
          }
          .barsName {
            height: 80px;
            display: flex;
            align-items: center;
            color: #fff;
            transition: all 0.3s ease;
            position: relative;
            border-bottom: 3px solid transparent;
          }
          .barsNameBg {
            color: #666;
            // border-bottom: 3px solid #666;
            border-bottom: 3px solid transparent;
          }
          .isActive {
            border-bottom: 3px solid #fff;
          }
          .isActive2 {
            border-bottom: 3px solid #666;
          }
        }
      }
      .loginBox {
        display: flex;
        .searchBox {
          width: 42px;
          height: 42px;
          cursor: pointer;
          border-radius: 50%;
          background: url(https://aqsc.jystudy.com/HZImage/pc/images/search.png)
            no-repeat;
          background-size: 100% 100%;
          // background-color: #ffffff;
          // border: 1px solid #0e75ee;
        }
        .follow {
          cursor: pointer;
          width: 120px;
          height: 42px;
          border: 1px solid #0e75ee;
          border-radius: 21px;
          margin-left: 15px;
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          > img {
            width: 16px;
            height: 22px;
            margin-right: 6px;
          }
          > p {
            font-size: 16px;
            color: #4587f0;
          }
        }
        .loginBtn:hover {
          .dialog {
            display: block;
          }
        }
        .loginBtn {
          cursor: pointer;
          min-width: 120px;
          padding: 0 10px;
          height: 42px;
          background: linear-gradient(-90deg, #4585f0, #51b6f0);
          border-radius: 21px;
          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
          > p {
            font-size: 16px;
            color: #ffffff;
          }
          .dialog {
            display: none;
            .dialogBox {
              width: 350px;
              position: absolute;
              right: 0;
              top: 61px;
              background-color: #fff;
              padding: 34px 25px;
            }
          }
        }
      }
    }
  }
}
.backgroundNavBar {
  background-color: #fff !important;
  box-shadow: 0px 0px 5px 0px #666;
}
/deep/ .el-dialog.cloudBody {
  // width: 500px!important;
  margin-top: 81px !important;
  margin-right: 40px !important;
}
.userInfo {
  margin-top: -15px;
  overflow: hidden;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
  .imgAndName {
    float: left;
    width: 70%;
    color: #000;
    .userImg {
      float: left;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    div {
      float: left;
      line-height: 25px;
      margin-left: 10px;
    }
  }
  .logOut {
    // width: 40px;
    line-height: 54px;
    padding-left: 15px;
    height: 54px;
    float: right;
    color: #0e75ee;
    cursor: pointer;
    border-left: #e8e8e8 1px dashed;
  }
}
.userOperation {
  overflow: hidden;
  div {
    width: 50%;
    float: left;
    margin-top: 30px;
    span {
      cursor: pointer;
      margin-left: 5px;
      &:hover {
        color: #0e75ee;
      }
    }
  }
}

/deep/.el-dialog--center .el-dialog__header {
  padding: 0 !important;
}
/deep/.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.login-box {
  height: 565px;
  display: flex;
  position: relative;
  .login-box-left {
    width: 470px;
    height: 565px;
  }
  .login-box-right {
    width: 470px;
    height: 565px;
    box-sizing: border-box;
    padding: 38px 47px;
    .login-title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }
    .login-choose {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      .item {
        cursor: pointer;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .item-active {
        color: #428efd;
      }
      .line {
        height: 15px;
        width: 2px;
        background-color: #428efd;
        margin: 0 14px;
      }
    }

    .login-choose2 {
      display: flex;
      align-items: center;
      .item {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #428efd;
        cursor: pointer;
      }
      .line {
        height: 15px;
        width: 1px;
        background-color: #999;
        margin: 0 10px;
      }
    }

    .text {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      img {
        width: 15px;
        height: 16px;
        margin-right: 8px;
      }
    }
    /deep/.el-button--primary {
      width: 100%;
      height: 46px;
    }
    .user-look {
      margin-top: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      & > span {
        color: #f58627;
        cursor: pointer;
      }
      /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #f58627 !important;
        border-color: #f58627 !important;
      }
      /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #f58627 !important;
      }
    }
    .user-look1 {
      margin-top: 0;
    }
    .user-look2 {
      margin-top: 20px;
      & > span {
        color: #428efd;
        cursor: pointer;
      }
    }
  }
  .login-box-cancel {
    position: absolute;
    top: -20px;
    width: 40px;
    height: 40px;
    right: -20px;
    cursor: pointer;
  }
}
/deep/.el-input__prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .el-input-group__append{
/deep/.el-button--default {
  padding: 11px 0 !important;
  width: 111px !important;
  background: #ffffff !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 400 !important;
  color: #428efd !important;
}
// }
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f58627 !important;
}
.operationBtn {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  .btn {
    width: 45%;
  }
}
.no-height {
  display: none;
  width: 1px;
}
.have-height {
  display: block;
  width: 1px;
}
.phone-label {
  position: relative;
  left: 5px;
}
// .vc-pic-box {
// }
/deep/.el-input-group__append {
  background-color: #fff !important;
}
/deep/ .el-cascader {
  width: 100%;
}
/deep/ .cascader .el-input__inner {
  padding-left: 30px;
}
.groupPosition {
  width: 100%;
  position: relative;
  .icons {
    position: absolute;
    left: 10px;
    opacity: 0.4;
    top: 13px;
  }
}
</style>

<template>
  <div id="app">
    <Header :showHeader="route.meta.showHeader" :isSkip = "route.meta.isSkip"></Header>
    <keep-alive>
      <router-view class="" v-if="route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view class="all-box" v-if="!route.meta.keepAlive"></router-view>
    <Footer v-if="route.meta.showFooter"></Footer>
    <SideBar v-if="SideBarShow"></SideBar>
  </div>
</template>

<script>
import Header from "./components/Header/header.vue";
import Footer from "./components/Footer/footer.vue";
import SideBar from "./components/Sidebar/index.vue";
import { KeepOnline } from "@/service/getData";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    SideBar
  },
  computed: {
    route() {
      return this.$route;
    },
  },
  data() {
    return {
      keeptimer: null,
      SideBarShow:true
    };
  },
  created() {
    this.KeepOnline();
  },
  watch:{
    '$route'(){
      if(this.$route.path =='/auditForm' || this.$route.path =='/toExamine' || this.$route.path =='/userOrderPage'){
      this.SideBarShow = false
    }
    }
  },
  methods: {
    async KeepOnline() {
      if (this.keeptimer != null) {
        clearInterval(keeptimer);
      }
      await KeepOnline({
        _: new Date().getTime(),
      });
      this.keeptimer = setInterval(async () => {
        await KeepOnline({
          _: new Date().getTime(),
        });
      }, 60000);
    },
  },
};
</script>

<style lang="less">
/deep/.ant-message {
  display: none !important;
  opacity: 0 !important;
}
/deep/.ant-message-notice-content {
  display: none !important;
}


.el-message-box{
  padding-bottom: 0px !important;
    border-radius: 10px !important;
    border: none !important;
    .el-message-box__header{
        padding: 10px 15px;
        background-color: #2C89D9;

        .el-message-box__title{
            font-size: 14px;
            color: #FFF;
        }
    }
     .el-message-box__content{
        padding: 50px;
        line-height: 22px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
    }
     .el-message-box__btns{
        display: flex;
        justify-content: center;
        background-color: #F0F4F7;
        padding: 15px 0 10px;
        /deep/.el-button{
          padding: 8px 15px !important;
        }
    }
}
.cursor{
  cursor: pointer;
}
</style>

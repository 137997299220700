const message = {
  routes: [
    {
      path: "/messageList",
      name: "messageList",
      meta: {
        keepAlive: true,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/message/messageList.vue"),
    },
    {
      path: "/messageShow",
      name: "messageShow",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/message/messageShow.vue"),
    },
    {
      path: "/downloadList",
      name: "downloadList",
      meta: {
        keepAlive: true,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,
      },
      component: () => import("@/view/message/downloadList.vue"),
    },
  ],
};
export default message;

const home = {
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      //主页
      path: "/home",
      name: "home",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: true,
        isSkip: true,

        breadcrumb: [
          {
            name: "首页",
            path: "/home",
          },
        ],
      },
      component: () => import("@/view/home/home.vue"),
    },
    {
      //主页
      path: "/searchList",
      name: "searchList",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: false,
        isShowNav: false,
        breadcrumb: [
          {
            name: "首页",
            path: "/home",
          },
        ],
      },
      component: () => import("@/view/home/searchList.vue"),
    },
    {
      //主页
      path: "/safetyTopic",
      name: "safetyTopic",
      meta: {
        keepAlive: false,
        showHeader: true,
        showFooter: true,
        isShowNav: false,
      },
      component: () => import("@/view/home/safetyTopic.vue"),
    },
    {
      //主页
      path: "/safetyTopicPlay",
      name: "safetyTopicPlay",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
      },
      component: () => import("@/view/home/safetyTopicPlay.vue"),
    },
    {
      //主页
      path: "/tryPlay",
      name: "tryPlay",
      meta: {
        keepAlive: false,
        showHeader: false,
        showFooter: false,
        isShowNav: false,
      },
      component: () => import("@/view/tryPlay/index.vue"),
    },
  ],
};
export default home;

import Vue from "vue";
import Router from "vue-router";
import home from "./home";
import message from "./message";
import user from "./user";
import train from "./train";
import person from "./person";
Vue.use(Router);

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const originPush = Router.prototype.push;
// 覆盖原型链的push
Router.prototype.push = function (location, resolve, reject) {
  // 判断用户有没有传后面两个可选参数
  if (resolve || reject) {
    return originPush.call(this, location, resolve, reject);
  } else {
    // 用户只传了第一个参数
    /* 
      默认底层： catch()方法代码  throw err : 抛出异常
      吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
      */
    return originPush.call(this, location).catch((err) => {
      err;
    });
  }
};

let routeList = [
  home.routes,
  message.routes,
  user.routes,
  train.routes,
  person.routes,
  { path: "*", redirect: "/home"},
];
let routes = [];
//拼接路由
routeList.map((s) => {
  routes = routes.concat(s);
});
// const router = new Router({
//   routes
// })

// router.afterEach(route => {
//   //将滚动条恢复到最顶端
//   window.scrollTo(0, 0)
// })
export default routes;

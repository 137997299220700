<template>
  <div class="sidebarBox">
    <div class="sideImgBox">
      <img src="https://aqsc.jystudy.com/Webroot/images/fwPhone.png" alt="" />
      <div class="hoverBox1">
        <p>0571-28990788转8006、8005</p>
      </div>
    </div>
    <div class="sideImgBox">
      <img src="https://aqsc.jystudy.com/Webroot/images/qq.png" alt="" />
      <div class="hoverBox2">
       <div style="display:flex">
        客服qq:
         
         <a
          href="http://wpa.qq.com/msgrd?v=3&amp;uin=308231535&amp;site=qq&amp;menu=yes"
          target="block"
          >308231535</a
        >
        <a
          href="http://wpa.qq.com/msgrd?v=3&amp;uin=253931496&amp;site=qq&amp;menu=yes"
          target="block"
          >253931496</a
        >
       </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sidebar",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.sidebarBox {
  position: fixed;
  right: 0;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
  .sideImgBox {
     color: #fff !important;
    > img {
      width: 50px;
      height: 50px;
      display: block;
    }
    .hoverBox1 {
      position: absolute;
      right: 0;
      top: 0;
      line-height: 50px;
      width: 300px;
      background: url(https://aqsc.jystudy.com/Webroot/images/phone-num.png)
        center no-repeat;
      display: none;
      > p {
        margin-left: 20px;
      }
    }
    .hoverBox2 {
      position: absolute;
      right: 0;
      top: 51px;
      background: url(https://aqsc.jystudy.com/Webroot/images/qq-num2.png) center
        no-repeat;
      width: 330px;
      height: 50px;
      line-height: 50px;
      text-indent: 20px;
      display: none;
      
    }
    &:nth-last-child(1) {
      margin-top: 1px;
    }
    &:hover {
      .hoverBox1 {
        display: block;
      }
      .hoverBox2 {
        display: block;
        a{
          color: #fff !important;
        }
      }
    }
  }
}
</style>
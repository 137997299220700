export const GET_USERINFO = 'GET_USERINFO'
export const GET_USERAGENT = 'GET_USERAGENT'
export const GET_NETWORKTYPE = 'GET_NETWORKTYPE'
export const SAVE_USERINFO = 'SAVE_USERINFO'
export const GET_SELECTED_ICONS = 'GET_SELECTED_ICONS'
export const SET_PERSONWINDOW_DISAPPEAR = 'SET_PERSONWINDOW_DISAPPEAR'
export const SET_NOTECHANGE_APPEAR = 'SET_NOTECHANGE_APPEAR'
export const SET_PLANCHANGE_APPEARCREATE = 'SET_PLANCHANGE_APPEARCREATE'
export const SET_PLANCHANGE_APPEARFIX = 'SET_PLANCHANGE_APPEARFIX'
export const SET_PLANSTARTTIME = 'SET_PLANSTARTTIME'
export const SET_PLANFINISHTIME = 'SET_PLANFINISHTIME'
export const SET_PLANAWAKEPERIOD = 'SET_PLANAWAKEPERIOD'
export const GET_EXAMID = 'GET_EXAMID'
export const GET_EXAMRESULT = 'GET_EXAMRESULT'
export const SET_NOTELABEL = 'SET_NOTELABEL'
export const SET_NOTEINFOR = 'SET_NOTEINFOR'
export const SET_HEADCHANGE_APPEAR = 'SET_HEADCHANGE_APPEAR'

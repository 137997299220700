/**
 * 页面接口api
 */
const API_URL = "/api";
const GenerateCode = "/GenerateCode";
const Platform = "/info";
const Platform1 = "/article";
const User = "/User";
const Pages = "/pages";
const Book = "/book";
const Exam = "/Exam";
const Portal = "/portal";
const Course = "/Course";
const Common = "/Common";
const Production = "/production";
const CourseInfo = "/courseinfo";
const Commment = "/comment";
const PcPlay = "/PcPlay";
const product = "/product";
const circle = "/circle";
const File = "/File";
const Training = "/Training";
const Guid = "/guid";
const Mobile = "/mobile";
const Activity = "/Activity";
const Vote = "/Vote";
const Enum = "/Enum";

// var API_URL = "https://www.zjaqxy.com/api";
// var API_URL = "http://122.225.101.117:9090/api";
var API_URL_ADMIN = API_URL + "/admin";

// const Collection = '/Collection'
export default {
  // // 获取活动分页列表
  // GetEnumList: {
  //   url: API_URL + Enum + '/GetEnumList',
  //   data: {  }
  // },
  // // 获取活动分页列表
  // GetActivityPageList: {
  //   url: API_URL + Activity + '/GetActivityPageList',
  //   data: {  }
  // },
  // // 获取活动详情
  // GetActivity: {
  //   url: API_URL + Activity + '/GetActivity',
  //   data: {  }
  // },
  // // 获取活动资源分页列表
  // GetActivityResourcePageList: {
  //   url: API_URL + Activity + '/GetActivityResourcePageList',
  //   data: {  }
  // },
  // // 获取活动资源详情
  // GetActivityResource: {
  //   url: API_URL + Activity + '/GetActivityResource',
  //   data: {  }
  // },
  // // 获取投票活动列表
  // GetVoteActivityPageList: {
  //   url: API_URL + Vote + '/GetVoteActivityPageList',
  //   data: {  }
  // },
  // // 获取投票作品
  // GetVoteOpus: {
  //   url: API_URL + Vote + '/GetVoteOpus',
  //   data: {  }
  // },
  // // 获取投票作品分页列表
  // GetVoteOpusPageList: {
  //   url: API_URL + Vote + '/GetVoteOpusPageList',
  //   data: {  }
  // },
  // // 获取投票信息
  // GetVoteInfo: {
  //   url: API_URL + Vote + '/GetVoteInfo',
  //   data: {  }
  // },
  // // 添加投票
  // AddVote: {
  //   url: API_URL + Vote + '/AddVote',
  //   data: {  }
  // },

  // // 判断用户是否在线
  // GetNoticeInfoList: {
  //   url: API_URL + Mobile + '/GetNoticeInfoList',
  //   data: {  }
  // },
  // // 判断用户是否在线
  // CheckUserIsExit: {
  //   url: API_URL + Platform + '/CheckAccountExit',
  //   data: { Account: '' }
  // },
  //   // 保持用户在线状态
  //   KeepOnline: {
  //     url: API_URL + User + '/KeepOnline',
  //     data: {}
  //   },
  //   // 检查用户登录状态
  //   Authorization: {
  //     url: API_URL + Pages + '/Authorization',
  //     data: {}
  //   },
  //   // 获取用户信息
  //   GetUerInfo: {
  //     url: API_URL + User + '/GetUserInfoModel',
  //     data: {}
  //   },
  //   // 获取用户学分
  //   LoginShort: {
  //     url: API_URL + User + '/LoginShort',
  //     data: {}
  //   },
  //   // 生成验证码
  //   GenerateCode: {
  //     url: API_URL + GenerateCode + '/GenerateCode',
  //     data: {}
  //   },
  //   // 验证短信验证码
  //   CheckValidateCode: {
  //     url: API_URL + Platform + '/CheckValidateCode',
  //     data: {}
  //   },
  //   // 验证码登录
  //   LoginCode: {
  //     url: API_URL + User + '/LoginCode',
  //     data: {}
  //   },
  //   // 退出登录
  //   LoginOut: {
  //     url: API_URL + User + '/LoginOut',
  //     data: {}
  //   },
  //   // 踢出其他地方登录
  //   KickOut: {
  //     url: API_URL + User + '/KickOut',
  //     data: {}
  //   },
  //   // 学员排行
  //   RankUserList: {
  //     url: API_URL + User + '/RankUserList',
  //     data: {}
  //   },
  //   // 首页街道排行
  //   RankGroupList: {
  //     url: API_URL + User + '/RankGroupList',
  //     data: {}
  //   },
  //   // 课程点击排行
  //   CourseClickRank: {
  //     url: API_URL + Course + '/CourseClickRank',
  //     data: {}
  //   },
  //   // 文章排行
  //   GetArticleRank: {
  //     url: API_URL + Platform1 + '/GetArticleRank',
  //     data: {}
  //   },
  //   // 修改密码
  //   UpdatePwd: {
  //     url: API_URL + User + '/UpdatePwd',
  //     data: {}
  //   },
  //   // 首页banner轮播图
  //   GetLink: {
  //     url: API_URL + Portal + '/GetLink',
  //     data: {}
  //   },
  //   // 首页实时数据
  //   LeftRealTimeData: {
  //     url: API_URL + Portal + '/LeftRealTimeData',
  //     data: {}
  //   },
  //   // 首页通知公告-通知公告中心右侧列表
  //   NoticeList: {
  //     url: API_URL + Platform1 + '/NoticeList',
  //     data: {}
  //   },
  //   // 首页新闻轮播-学习展示-地方动态
  //   GetArticleInfoList: {
  //     url: API_URL + Platform1 + '/GetArticleInfoList',
  //     data: {}
  //   },
  //   // 首页-课程
  //   CourseList: {
  //     url: API_URL + Course + '/CourseTypeIndex',
  //     data: {}
  //   },
  //   // 消息详情
  //   NoticeContent: {
  //     url: API_URL + Platform1 + '/NoticeContent',
  //     data: {}
  //   },
  //   // 未读消息
  //   UnReadNotice: {
  //     url: API_URL + User + '/UnReadNotice',
  //     data: {}
  //   },
  //   // 获取文章频道列表信息
  //   GetArticleChannelInfoList: {
  //     url: API_URL + Platform1 + '/GetArticleChannelInfoList',
  //     data: {}
  //   },
  //    // 获取文章频道列表信息
  //    NoticeCategory: {
  //     url: API_URL + Platform1 + '/NoticeCategory',
  //     data: {}
  //   },
  //   // 获取文章内容
  //   ArticleContent: {
  //     url: API_URL + Platform1 + '/ArticleContent',
  //     data: {}
  //   },
  //   // 获取文章点赞
  //   AddUserAssist: {
  //     url: API_URL + Platform1 + '/AddUserAssist',
  //     data: {}
  //   },
  //   // 删除文章点赞
  //   DelUserAssist: {
  //     url: API_URL + Platform1 + '/DelUserAssist',
  //     data: {}
  //   },
  //   // 添加收藏
  //   FavoriteAdd: {
  //     url: API_URL + User + '/FavoriteAdd',
  //     data: {}
  //   },
  //   // 取消收藏
  //   FavoriteDelete: {
  //     url: API_URL + User + '/FavoriteDelete',
  //     data: {}
  //   },
  //   // 记录已读 给积分
  //   SetArticleRed: {
  //     url: API_URL + Platform1 + '/SetArticleRed',
  //     data: {}
  //   },
  //   // 电子书频道
  //   BookCategory: {
  //       url: API_URL + Book + '/BookCategory',
  //       data: {}
  //   },
  //   // 首页 电子书列表
  //   BookList: {
  //       url: API_URL + Book + '/BookList',
  //       data: {}
  //   },
  //   // 电子书内容
  //   BookContent: {
  //       url: API_URL + Book + '/BookContent',
  //       data: {}
  //   },
  //   // 章节列表
  //   BookChapterList: {
  //       url: API_URL + Book + '/BookChapterList',
  //       data: {}
  //   },
  //   // 章节详情
  //   BookChapterContent: {
  //       url: API_URL + Book + '/BookChapterContent',
  //       data: {}
  //   },
  //   // 我的课程
  //   myCourseList: {
  //       url: API_URL + Course + '/MyCenter',
  //       data: {}
  //   },
  //   // 删除我的课程删除学习中的课程
  //   DelUserCourseReg: {
  //       url: API_URL + Course + '/DelUserCourseReg',
  //       data: {}
  //   },
  //   // 我的课程收藏
  //   MyCourseCollect: {
  //      url: API_URL + User + '/MyCourseFavorite',
  //      data: { Rows: 5 }
  //   },
  //   // 我的文章收藏
  //   MyEssayCollect: {
  //      url: API_URL + User + '/MyArticleFavorite',
  //      data: { Rows: 6 }
  //   },
  //   // 修改用户信息
  //   UpdateUserInfo: {
  //      url: API_URL + User + '/UpdateUserInfo',
  //      data: {}
  //   },
  //   // 我的作品收藏
  //   MyProductCollect: {
  //      url: API_URL + User + '/MyProductionFavorite',
  //      data: { Rows: 9 }
  //   },
  //   // 查看收藏
  //   FavoriteExist: {
  //     url: API_URL + User + '/FavoriteExist',
  //     data: { }
  //   },
  //   // 电子书收藏
  //   MyBookFavorite: {
  //     url: API_URL + User + '/MyBookFavorite',
  //     data: {}
  //   },
  //   // 删除收藏
  //   CollectDelete: {
  //      url: API_URL + User + '/FavoriteDelete',
  //      data: {}
  //   },
  //   // 个人学习档案
  //   StudyStatistics: {
  //      url: API_URL + User + '/StudyStatistics',
  //      data: {}
  //   },
  //   // 考试中心
  //   ExamCenter: {
  //      url: API_URL + Exam + '/ExamList',
  //      data: { Rows: 20 }
  //   },
  //   // 考试详细信息
  //   GetExamInfo: {
  //     url: API_URL + Exam + '/GetExamInfo',
  //     data: {}
  //   },
  //   // 考试种类获取
  //   GetExamType: {
  //     url: API_URL + Exam + '/GetExamType',
  //     data: {}
  //   },
  //   // 考试结果提交
  //   PostExam: {
  //     url: API_URL + Exam + '/PostExam',
  //     data: {}
  //   },
  //   // 考试结果获取
  //   GetExamReviewInfo: {
  //     url: API_URL + Exam + '/GetExamReviewInfo',
  //     data: {}
  //   },
  //   // 考试列表页
  //   ExamListItem: {
  //     url: API_URL + Exam + '/ExamListItem',
  //     data: { Rows: 20 }
  //   },
  //   // 注册页面获取镇区列表
  //   GetAllGroupList: {
  //     url: API_URL + Common + '/GetAllGroupList',
  //     data: {}
  //   },
  //   // 登录注册页验证码获取
  //   GetLoginVC: {
  //     url: API_URL + Common + '/GetLoginVC',
  //     data: {}
  //   },
  //   // 注册接口
  //   Register: {
  //     url: API_URL + User + '/Register',
  //     data: {}
  //   },
  //   // 获取网上展厅的分类
  //   ProductionCategoryInfoList: {
  //     url: API_URL + Production + '/ProductionCategoryInfoList',
  //     data: {}
  //   },
  //   // 获取网上展厅列表内容
  //   ProductionInfoList: {
  //     url: API_URL + Production + '/ProductionInfoList',
  //     data: {}
  //   },
  //   // 获取网上展厅详细内容
  //   ProductionDetail: {
  //     url: API_URL + Production + '/ProductionDetail',
  //     data: {}
  //   },
  //   // 获取网上展厅随机内容
  //   ProductionListTask: {
  //     url: API_URL + Production + '/ProductionListTask',
  //     data: {}
  //   },
  //   // 下载网上展厅数据
  //   DownLoadImages: {
  //     url: API_URL + Production + '/DownLoadImages',
  //     data: {}
  //   },
  //   // 课程分类-左侧导航
  //   CourseCategory: {
  //     url: API_URL + Course + '/CourseCategory',
  //     data: {}
  //   },
  //   // 获取课程类型
  //   GetCourseType: {
  //     url: API_URL + CourseInfo + '/GetCourseType',
  //     data: {}
  //   },
  //   // 课程中心课程列表
  //   CourseList2: {
  //     url: API_URL + Course + '/CourseList',
  //     data: {}
  //   },
  //   // 课程内容
  //   CourseContent: {
  //     url: API_URL + Course + '/CourseContent',
  //     data: {}
  //   },
  //   // 课程内容课程评论
  //   CourseComment: {
  //     url: API_URL + '/Comment' + '/CourseComment',
  //     data: {}
  //   },
  //   // 添加电子书-课程评论
  //   AddComment: {
  //     url: API_URL + Commment + '/AddComment',
  //     data: {}
  //   },
  //   // 评论列表 评论类型 All 全部， Course 课程，Knowledge 知识点，Series 专题， Message 留言板， Book 电子书，Article 文章，Production 展厅
  //   CommentList: {
  //     url: API_URL + Commment + '/CommentList',
  //     data: {}
  //   },
  //   // 获取课程视频的内容
  //   Play: {
  //     url: API_URL + PcPlay + '/Play',
  //     data: {}
  //   },
  //   // 获取MP4视频数据
  //   PlayJwplay: {
  //     url: API_URL + PcPlay + '/PlayJwplay',
  //     data: {}
  //   },
  //   // 防止播放多个视频
  //   ClearPlayingCourse: {
  //     url: API_URL + '/Common' + '/ClearPlayingCourse',
  //     data: {}
  //   },
  //   // Refresh接口
  //   Refresh: {
  //     url: API_URL + '/CourseProcess' + '/Refresh',
  //     data: {}
  //   },
  //   // 积分商城类别查询
  //   ShopCenter: {
  //     url: API_URL + product + '/ProductTypeList',
  //     data: {}
  //   },
  //   // 积分商城商品列表查询
  //   ProductList: {
  //     url: API_URL + product + '/ProductList',
  //     data: {}
  //   },
  //   // 积分商城商品详细信息
  //   ProductDetail: {
  //     url: API_URL + product + '/ProductDetail',
  //     data: {}
  //   },
  //   // 积分商城商品兑换
  //   SendRecord: {
  //     url: API_URL + product + '/SendRecord',
  //     data: {}
  //   },
  //   // 积分商城获取热门商品
  //   ProductHotList: {
  //     url: API_URL + product + '/ProductHotList',
  //     data: {}
  //   },
  //   // 记录mp4视频进度
  //   SingleProcess: {
  //     url: API_URL + '/CourseProcess' + '/SingleProcess',
  //     data: {}
  //   },
  //   // 课程播放页评论
  //   CourseComment2: {
  //     url: API_URL + Commment + '/CourseComment',
  //     data: {}
  //   },
  //   // 防伪造请求
  //   AntiForgeryToken: {
  //     url: API_URL + '/Pages' + '/AntiForgeryToken',
  //     data: {}
  //   },
  //   // 课程播放页添加笔记
  //   AddNote: {
  //     url: API_URL + '/Course' + '/AddNote',
  //     data: {}
  //   },
  //   // 课程播放页删除笔记
  //   DelNote: {
  //     url: API_URL + Course + '/DelNote',
  //     data: {}
  //   },
  //   // 课程播放页添加评论
  //   CourseCommentAdd: {
  //     url: API_URL + '/Comment' + '/CourseCommentAdd',
  //     data: {}
  //   },
  //   // 获取精英课程-三分屏课程的信息
  //   PlayJY: {
  //     url: API_URL + PcPlay + '/PlayJY',
  //     data: {}
  //   },

  //   // 学习圈类型
  //   CircleTypeInfoList: {
  //     url: API_URL + circle + '/CircleTypeInfoList',
  //     data: {}
  //   },
  //   // 学习圈列表
  //   CircleInfoList: {
  //     url: API_URL + circle + '/CircleInfoList',
  //     data: {}
  //   },
  //   // 热门学习圈
  //   CircleHotInfoList: {
  //     url: API_URL + circle + '/CircleHotInfoList',
  //     data: {}
  //   },
  //   // 学习圈文章列表
  //   CircleArticleList: {
  //     url: API_URL + circle + '/CircleArticleList',
  //     data: {}
  //   },
  //   // 上传学习圈
  //   CircleCreate: {
  //     url: API_URL + circle + '/CircleCreate',
  //     data: {}
  //   },
  //   // 圈内达人
  //   CircleArticleHotModel: {
  //     url: API_URL + circle + '/CircleArticleHotModel',
  //     data: {}
  //   },
  //   // 学习圈详情
  //   CircleDetail: {
  //     url: API_URL + circle + '/CircleDetail',
  //     data: {}
  //   },
  //   // 上传学习圈文章
  //   CircleArticleCreate: {
  //     url: API_URL + circle + '/CircleArticleCreate',
  //     data: {}
  //   },
  //   // 获取课程计划
  //   StudyPlanGet: {
  //     url: API_URL + Course + '/StudyPlanGet',
  //     data: {}
  //   },
  //   // 上传计划修改
  //   StudyPlanUpdate: {
  //     url: API_URL + Course + '/StudyPlanUpdate',
  //     data: {}
  //   },
  //   // 添加新的修改计划
  //   StudyPlanAdd: {
  //     url: API_URL + Course + '/StudyPlanAdd',
  //     data: {}
  //   },
  //   // 我的学习计划
  //   MyStudyPlan: {
  //     url: API_URL + User + '/MyStudyPlan',
  //     data: {}
  //   },
  //   // 删除学习计划
  //   DelStudyPlan: {
  //     url: API_URL + Course + '/DelStudyPlan',
  //     data: {}
  //   },
  //   //  附件上传
  //   UploadAttachment: {
  //     url: API_URL + File + '/UploadAttachment',
  //     data: {}
  //   },
  //   // 培训班
  //   TrainingClass: {
  //     url: API_URL + Training + '/TrainingClass',
  //     data: {}
  //   },
  //   // 培训班类型列表
  //   GetTrainingClassTypeList: {
  //     url: API_URL + Training + '/GetTrainingClassTypeList',
  //     data: {}
  //   },
  //   // 学校列表
  //   GetTrainingSchoolList: {
  //     url: API_URL + Training + '/GetTrainingSchoolList',
  //     data: {}
  //   },
  //   // 培训班详情页
  //   GetTrainingById: {
  //     url: API_URL + Training + '/GetTrainingById',
  //     data: {}
  //   },
  //   // 培训班报名(带批量)
  //   TrainingSign: {
  //     url: API_URL + Training + '/TrainingSign',
  //     data: {}
  //   },
  //   // 获取课程笔记
  //   CourseNoteList: {
  //     url: API_URL + Course + '/CourseNoteList',
  //     data: {}
  //   },
  //   // 获取笔记信息
  //   NoteUpdate: {
  //     url: API_URL + Course + '/NoteUpdate',
  //     data: {}
  //   },
  //   // 获取评论种类
  //   GetCommentType: {
  //     url: API_URL + Commment + '/GetCommentType',
  //     data: {}
  //   },
  //   // 获取我的评论
  //   MyCommentList: {
  //     url: API_URL + Commment + '/MyCommentList',
  //     data: {}
  //   },
  //   // 获取我的考试信息
  //   MyExamStat: {
  //     url: API_URL + Exam + '/MyExamStat',
  //     data: {}
  //   },
  //   // 上传用户图片
  //   SetUserPhoto: {
  //     url: API_URL + User + '/SetUserPhoto'
  //   },
  //   // 课程推荐
  //   RecommendCourse: {
  //     url: API_URL + Guid + '/RecommendCourse'
  //   },
  //   AddQualityResources: {
  //     url: API_URL + CourseInfo + '/AddQualityResources'
  //   },
  //   // 培训课程
  //   trainingStudyCourseList:{
  //     url:API_URL +Training +'/GetTrainingStudyCourseList'
  //   },
  //   // 我的消息
  //   GetMessageCenter:{
  //     url: API_URL +'/Article/NoticeUnReadList',
  //     data: {}
  //   },
  //   //我的培训班 Training/GetTrainingList
  //   GetTrainingList:{
  //     url: API_URL +'/Training/GetTrainingList',
  //   },
  //   // 培训班课程 Training/GetTrainingCourseList
  //   GetTrainingCourseList:{
  //     url: API_URL +'/Training/GetTrainingCourseList',
  //   },

  //   GetTrainingProjectList:{
  //     url: API_URL +'/Training/GetTrainingProjectList',
  //   },
  //   //培训项目详情
  //   GetTrainingProjectById:{
  //     url: API_URL +'/Training/GetTrainingProjectById',
  //   },
  //   //培训项目资讯列表
  //   GetTrainingProjecArticletList:{
  //     url: API_URL +'/Training/GetTrainingProjecArticletList',
  //   },
  //   //培训项目资讯详情
  //   GetTrainingProjectArticleById:{
  //     url: API_URL +'/Training/GetTrainingProjectArticleById',
  //   },
  //   //培训班详情
  //   GetTraining:{
  //     url: API_URL +'/Training/GetTraining',
  //   },
  //   //
  //   //培训文章或文本资源
  //   GetTrainingArticleList:{
  //     url: API_URL +'/Training/GetTrainingArticleList',
  //   },
  //   //获取培训班文章或者文本资源详情
  //   GetTrainingArticle:{
  //     url: API_URL +'/Training/GetTrainingArticle',
  //   },
  //   //
  //   AddTrainingResourceOrder:{
  //     url: API_URL +'/Training/AddTrainingResourceOrder',
  //   },
  //   //
  //   GetTrainingRankList:{
  //     url: API_URL +'/Training/GetTrainingRankList',
  //   },
  //   GetTrainingExamList:{
  //     url: API_URL +'/Training/GetTrainingExamList',
  //   },
  //   GetOrderList:{
  //     url: API_URL +'/Training/GetOrderList',
  //   },
  //   DeleteOrderInfo:{
  //     url: API_URL +'/Training/DeleteOrderInfo',
  //   },
  //   GetTrainingUser:{
  //     url: API_URL +'/Training/GetTrainingUser',
  //   },
  //   GetExamRank:{
  //     url: API_URL +'/mobile/GetExamRank',
  //   },
  //   FindPasswordByMobile:{
  //     url: API_URL +'/User/FindPasswordByMobile',
  //   },
  //   aidioute:{
  //     url: 'https://apia.aidioute.cn/weather/index.php?location_type=0'
  //   },

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //应急接口

  //判断能否访问页面接口
  Authorization: {
    url: API_URL + "/Page/Authorization",
    data: { controller: "Do", action: "" },
  },
  //保持在线
  KeepOnline: {
    url: API_URL + "/Page/KeepOnline",
  },
  //文章分类
  ArticleCategory: {
    url: API_URL + "/Page/ArticleCategory",
    data: { titleNav: "文章分类", sort: "Sort", order: "desc" },
  },
  //文章
  ArticleList: {
    url: API_URL + "/Page/ArticleList",
    data: {
      page: "1",
      rows: "7",
      sort: "Sort",
      order: "desc",
      wordLimt: "20",
      categoryCode: "",
      categoryId: "",
    },
  },
  //文章内容
  ArticleContent: {
    url: API_URL + "/Page/ArticleContent",
    data: {},
  },

//文章内容
GetGroupType: {
  url: API_URL + "/traininginfo/GetGroupType",
  data: {},
},

  //搜索全部新闻
  SearchAll: {
    url: API_URL + "/Page/SearchAll",
    data: { page: 1, rows: 15, key: "" },
  },
  //通知公告
  noticeAnnouncement: {
    url: API_URL + "/Page/LeftNotice",
    data: { page: "1", rows: "4", sort: "Sort", order: "desc", wordLimt: "15" },
  },
  //通告公告列表
  NoticeList: {
    url: API_URL + "/Page/NoticeList",
    data: {
      page: "1",
      rows: "10",
      sort: "Id",
      order: "desc",
      categoryId: 0,
      titleNav: "通告公告",
      wordLimt: "35",
    },
  },
  //通知内容
  noticeContent: {
    url: API_URL + "/Page/NoticeContent",
    data: { Id: "", titleNav: "通知内容" },
  },
  //友情链接
  Blogroll: {
    url: API_URL + "/Page/Blogroll",
    data: {},
  },
  //培训课程
  TrainingClass: {
    url: API_URL + "/Page/TrainingClass",
    data: { sort: "Id", order: "desc" },
  },
  //请求用户信息
  LoginShort: {
    url: API_URL + "/Page/LoginShort",
    data: {},
  },
  //防伪造请求
  AntiForgeryToken: {
    url: API_URL + "/Page/AntiForgeryToken",
    data: {},
  },
  //用户退出
  LoginOut: {
    url: API_URL + "/Page/LoginOut",
    data: {},
  },
  //踢出其他地方登录账号
  KickOut: {
    url: API_URL + "/Page/KickOut",
    data: {},
  },
  //点击登陆（记住密码）
  LoginMe: {
    url: API_URL + "/Page/LoginMe",
    data: {},
  },
  //点击登陆（没有记住密码）
  LoginCode: {
    url: API_URL + "/Page/LoginCode",
    data: {},
  },
  //登陆页面登录（验证码）
  Login: {
    url: API_URL + "/Page/Login",
    data: {},
  },
  //获取验证码
  GetVerifyCode: {
    url: API_URL + "/common/GetLoginVC",
    data: {},
  },
  /*//获取验证码
  GetVerifyCode: {
    url: API_URL + "/common/GetVerifyCode",
    data: {}
  },*/
  //记住密码
  GetLoginName: {
    url: API_URL + "/Page/GetLoginName",
    data: {},
  },
  // 获取二维码
  GetLoginQRCode: {
    url: API_URL + "/auth/GetLoginQRCode",
    data: {},
  },
  // 轮训扫码结果
  CheckLoginQRCode: {
    url: API_URL + "/auth/CheckLoginQRCode",
    data: { token: "" },
  },
  //单位排行
  LeftGroupRank: {
    url: API_URL + "/Page/LeftGroupRank",
    data: {
      page: 1,
      rows: 10,
      sort: "AvgCredit",
      order: "desc",
      titleNav: "单位排行",
      wordLimt: 9,
    },
  },
  //单位排行详情
  RankGroupList: {
    url: API_URL + "/Page/RankGroupList",
    data: {
      page: 1,
      rows: 15,
      sort: "AvgCredit",
      order: "desc",
      titleNav: "单位排行",
      wordLimt: 30,
    },
  },
  //实时数据
  LeftRealTimeData: {
    url: API_URL + "/Page/LeftRealTimeData",
    data: {},
  },
  //用户素质维度
  GetUserSkill: {
    url: API_URL + "/Guid/GetUserSkill",
    data: {},
  },
  //课程分类
  CourseCategory: {
    url: API_URL + "/Page/CourseCategory",
    data: { titleNav: "课程分类", sort: "Sort", order: "Desc" },
  },
  //推荐课程
  RecommendCourse: {
    url: API_URL + "/Guid/RecommendCourse",
    data: { page: 1, rows: 10 },
  },
  //相关课程
  RelatedCourse: {
    url: API_URL + "/Guid/RelatedCourse",
    data: { Page: 1, Rows: 6, CourseId: "" },
  },
  //课程列表
  CourseList: {
    url: API_URL + "/Page/CourseList",
    data: {
      page: 1,
      rows: 10,
      sort: "Sort",
      order: "desc",
      courseType: "All",
      channelId: "",
      channelCode: "",
      title: "",
      titleNav: "课程中心",
      wordLimt: 35,
      teacher: "",
    },
  },
  //课程点击排行
  CourseClickRank: {
    url: API_URL + "/Page/CourseClickRank",
    data: {
      page: 1,
      rows: 19,
      sort: "ClickCount",
      order: "desc",
      courseType: "All",
      flag: "All",
      titleNav: "课程排行",
      wordLimt: 35,
    },
  },
  //课程点击排行详细页
  CourseClickList: {
    url: API_URL + "/Page/CourseClickList",
    data: {
      page: 1,
      rows: 20,
      sort: "ClickCount",
      order: "desc",
      titleNav: "课程点击排行",
      wordLimt: 17,
    },
  },
  //登录次数排行
  LoginSumRank: {
    url: API_URL + "/Page/LoginSumRank",
    data: {
      page: 1,
      rows: 10,
      sort: "LoginTimes",
      order: "desc",
      titleNav: "登录次数排行",
      wordLimt: 6,
    },
  },
  //登录次数排行详细页
  LoginSumList: {
    url: API_URL + "/Page/LoginSumList",
    data: {
      page: 1,
      rows: 15,
      sort: "LoginTimes",
      order: "desc",
      titleNav: "登录次数排行",
    },
  },
  //批量选课
  AddStudyCourse: {
    url: API_URL + "/Page/AddStudyCourse",
    data: { checkValue: "" },
  },
  //个人中心个人信息
  LoginLong: {
    url: API_URL + "/Page/LoginLong",
    data: {},
  },
  //学员学时排行
  LeftUserRank: {
    url: API_URL + "/Page/LeftUserRank",
    data: {
      page: 1,
      rows: 10,
      sort: "TotalCredit",
      order: "desc",
      titleNav: "个人排行",
      wordLimt: 6,
    },
  },
  //个人排行
  RankUserList: {
    url: API_URL + "/Page/RankUserList",
    data: {
      page: 1,
      rows: 15,
      sort: "TotalCredit",
      order: "desc",
      titleNav: "个人排行",
    },
  },
  //课程完成排行
  CourseFinishList: {
    url: API_URL + "/Page/CourseFinishList",
    data: {
      page: 1,
      rows: 15,
      sort: "FinishCourseCount",
      order: "desc",
      titleNav: "课程完成排行",
      wordLimt: 8,
    },
  },
  //个人中心课程
  MyCenter: {
    url: API_URL + "/Page/MyCenter",
    data: {
      page: 1,
      rows: 10,
      sort: "BrowseScore",
      order: "desc",
      titleNav: "个人中心",
      courseType: "Unfinish",
      title: "",
    },
  },
  //考试中心列表
  ExamList: {
    url: API_URL + "/Page/ExamList",
    data: {
      page: 1,
      rows: 5,
      sort: "Id",
      order: "desc",
      titleNav: "在线考试",
      examType: "All",
      title: "",
    },
  },
  //问卷调查列表
  PollList: {
    url: API_URL + "/Page/PollList",
    data: {
      page: 1,
      rows: 10,
      sort: "Id",
      order: "desc",
      titleNav: "问卷调查",
      examType: "All",
      title: "",
      trainingId: "",
      wordLimt: 30,
    },
  },
  //参加问卷调查
  Poll: {
    url: API_URL + "/Exam/Poll",
    data: { parameter1: "" },
  },
  //参加问卷调查2
  Poll2: {
    url: API_URL + "/Exam/Poll2",
    data: { pollId: "" },
  },
  //问卷调查结果
  PollReview: {
    url: API_URL + "/Exam/PollReview",
    data: { parameter1: "", parameter2: "" },
  },
  PostPoll: {
    url: API_URL + "/Exam/PostPoll",
    data: {},
  },
  //问卷调查结果2
  PollReview2: {
    url: API_URL + "/Exam/PollReview2",
    data: { parameter1: "", parameter2: "" },
  },
  //培训班分类
  GetTrainingClassTypeList: {
    url: API_URL + "/Page/GetTrainingClassTypeList",
    data: { titleNav: "培训班分类", sort: "Group", order: "asc" },
  },
  //专题学习
  StudySpecial: {
    url: API_URL + "/Page/StudySpecial",
    data: { titleNav: "专题学习", sort: "Sort", order: "Desc" },
  },
  //我的班级
  ClassMy: {
    url: API_URL + "/Page/ClassMy",
    data: {
      page: 1,
      sort: "Id",
      order: "desc",
      titleNav: "我的班级",
      title: "",
    },
  },
  //活跃班级
  ClassActive: {
    url: API_URL + "/Page/ClassActive",
    data: {
      page: 1,
      rows: 7,
      sort: "CurrentUser",
      order: "desc",
      titleNav: "活跃班级",
    },
  },
  //近期班级
  ClassRecent: {
    url: API_URL + "/Page/ClassRecent",
    data: {
      page: 1,
      rows: 10,
      sort: "StartDate",
      order: "desc",
      titleNav: "近期班级",
    },
  },
  //获取培训列表
  GetClassList: {
    url: API_URL + "/Page/GetClassList",
    data: {
      page: 1,
      rows: 8,
      sort: "Id",
      order: "desc",
      title: "",
      type: "all",
      categoryId: 0,
    },
  },
  //我的班级（type："my" 我的班级 type："recent" 近期班级 type："active" 活跃班级）
  ClassList: {
    url: API_URL + "/Page/ClassList",
    data: {
      page: 1,
      rows: 20,
      sort: "Id",
      order: "desc",
      title: "",
      type: "",
      wordLimt: 30,
    },
  },
  //课程详情
  CourseContent: {
    url: API_URL + "/Page/CourseContent",
    data: { Id: "", titleNav: "课程详情" },
  },
  //学习统计
  MyStudyStat: {
    url: API_URL + "/Page/MyStudyStat",
    data: {
      page: 1,
      rows: 10,
      sort: "Id",
      order: "desc",
      titleNav: "学习统计",
    },
  },
  //考试统计
  MyExamStat: {
    url: API_URL + "/Page/MyExamStat",
    data: {
      page: 1,
      rows: 10,
      title: "",
      sort: "Id",
      order: "desc",
      titleNav: "考试统计",
    },
  },
  //我的收藏
  MyFavorite: {
    url: API_URL + "/Page/MyFavorite",
    data: {
      page: 1,
      rows: 10,
      sort: "Id",
      order: "desc",
      titleNav: "我的收藏",
    },
  },
  //收藏
  FavoriteAdd: {
    url: API_URL + "/Page/FavoriteAdd",
    data: { mainId: "", type: "", title: "", remark: "" },
  },
  //取消收藏
  FavoriteDelete: {
    url: API_URL + "/Page/FavoriteDelete",
    data: { id: "" },
  },
  //学习计划
  MyStudyPlan: {
    url: API_URL + "/Page/MyStudyPlan",
    data: {
      page: 1,
      rows: 10,
      sort: "Id",
      order: "desc",
      titleNav: "学习计划",
    },
  },
  //获取笔记
  NoteAdd: {
    url: API_URL + "/Home/NoteAdd",
    data: { courseId: "" },
  },
  //添加笔记
  AddNote: {
    url: API_URL + "/Page/AddNote",
    data: { Name: "", Content: "", CourseId: "" },
  },
  //笔记列表
  CourseNoteList: {
    url: API_URL + "/Page/CourseNoteList",
    data: { CourseId: "" },
  },
  //编辑笔记
  NoteUpdate: {
    url: API_URL + "/Page/NoteUpdate",
    data: {},
  },
  //删除笔记
  DelNote: {
    url: API_URL + "/Page/DelNote",
    data: { Id: "" },
  },

  //添加计划
  StudyPlanAdd: {
    url: API_URL + "/Home/StudyPlanAdd",
    data: { courseId: "" },
  },
  //添加计划提交
  AddStudyPlan: {
    url: API_URL + "/Page/StudyPlanAdd",
    data: {
      PlanFinishDate: "",
      RemindDate: "",
      RemindCycle: "",
      Remark: "",
      CourseId: "",
    },
  },
  //查看计划
  StudyPlanUpdate: {
    url: API_URL + "/Home/StudyPlanUpdate",
    data: { courseId: "" },
  },
  //提交修改计划
  EditStudyPlanUpdate: {
    url: API_URL + "/Page/StudyPlanUpdate",
    data: {
      PlanFinishDate: "",
      RemindDate: "",
      RemindCycle: "",
      Remark: "",
      CourseId: "",
      Id: "",
    },
  },
  //删除课程
  DelUserCourseReg: {
    url: API_URL + "/Page/DelUserCourseReg",
    data: { courseId: "" },
  },
  //考试查看
  CourseExamList: {
    url: API_URL + "/Page/CourseExamList",
    data: { courseId: "" },
  },
  //删除学习计划
  DelStudyPlan: {
    url: API_URL + "/Page/DelStudyPlan",
    data: { id: "" },
  },
  //留言板
  MessageList: {
    url: API_URL + "/Page/MessageList",
    data: {
      page: 1,
      rows: 13,
      sort: "CreateDate",
      order: "desc",
      titleNav: "留言板",
      wordLimt: "35",
    },
  },
  //留言信息详情
  MessageDetail: {
    url: API_URL + "/Page/MessageDetail",
    data: { id: "", titleNav: "留言详情" },
  },
  //提交添加留言
  GetMessageAdd: {
    url: API_URL + "/Page/GetMessageAdd",
    data: { Name: "", Class: "", Content: "" },
  },
  //考试题
  Exam: {
    url: API_URL + "/Exam/Exam",
    data: { parameter1: "" },
  },
  //个人学习档案
  StudyStatistics: {
    //type userinfo study exam training
    url: API_URL + "/Page/StudyStatistics",
    data: {
      page: 1,
      rows: 5,
      sort: "Id",
      order: "desc",
      type: "",
      startDate: "",
      endDate: "",
      wordLimt: 24,
      titleNav: "个人学习档案",
    },
  },
  //考试详情
  ExamDetailListItem: {
    url: API_URL + "/Exam/ExamDetailListItem",
    data: { page: 1, rows: 10, sort: "Id", order: "desc", examid: "" },
  },
  //考试记录详情
  ExamReview: {
    url: API_URL + "/Exam/ExamReview",
    data: { parameter1: "", parameter2: "" },
  },
  //报名培训班
  UpdateTrainingStudentup: {
    url: API_URL + "/traininginfo/UpdateTrainingStudentup",
    data: {},
  },
  //取消报名培训班
  UpdateTrainingStudentdown: {
    url: API_URL + "/traininginfo/UpdateTrainingStudentdown",
    data: { Id: "" },
  },
  //未读通知
  UnReadNotice: {
    url: API_URL + "/Page/NoticeUnReadList",
    data: {
      page: 1,
      rows: 7,
      sort: "Id",
      order: "desc",
      titleNav: "个人通知",
      wordLimt: 30,
    },
  },
  //修改密码
  UpdatePwd: {
    url: API_URL + "/Page/UpdatePwd",
    data: {},
  },
  //设置密保--密码验证
  SetPasswordQuestion: {
    url: API_URL + "/Page/SetPasswordQuestion",
    data: {},
  },
  //设置密保--添加密保问题
  AddPasswordQuestion: {
    url: API_URL + "/Page/AddPasswordQuestion",
    data: { pwd: "" },
  },
  //修改信息--获取用户详细信息
  GetUserInfo: {
    url: API_URL + "/Page/GetUserInfo",
    data: { titleNav: "修改信息" },
  },
  //修改信息--获取职位分类
  GetGradeList: {
    url: API_URL + "/Common/GetGradeList",
    data: {},
  },
  //修改用户信息
  UpdateUserInfo: {
    url: API_URL + "/Page/UpdateUserInfo",
    data: {},
  },
  //专题培训班--个人学习信息
  ClassInformation: {
    url: API_URL + "/Page/ClassInformation",
    data: { titleNav: "个人学习信息", Id: "" },
  },
  //专题培训班--班级详情
  ClassDetail: {
    url: API_URL + "/Page/ClassDetail",
    data: {
      titleNav: "培训班详情",
      page: 1,
      rows: 9,
      sort: "Id",
      order: "desc",
    },
  },
  //专题培训班--教学计划
  ClassPlan: {
    url: API_URL + "/Page/ClassPlan",
    data: { titleNav: "教学计划" },
  },
  //专题培训班--同学名录
  ClassStudent: {
    url: API_URL + "/Page/ClassStudent",
    data: {
      page: 1,
      rows: 12,
      sort: "Id",
      order: "desc",
      titleNav: "同学名录",
    },
  },
  //专题培训班--我的论文
  ClassPaperList: {
    url: API_URL + "/Page/ClassPaperList",
    data: {
      page: 1,
      rows: 12,
      sort: "Id",
      order: "desc",
      titleNav: "班级论文",
      wordLimt: 30,
    },
  },
  //专题培训班--班级相册
  PhotoAlbumList: {
    url: API_URL + "/Page/PhotoAlbumList",
    data: { page: 1, rows: 9, sort: "Id", order: "desc", titleNav: "班级相册" },
  },
  //专题培训班--班级话题
  ClassTopicList: {
    url: API_URL + "/Page/ClassTopicList",
    data: {
      page: 1,
      rows: 12,
      sort: "Id",
      order: "desc",
      titleNav: "班级话题",
      wordLimt: 30,
    },
  },
  //专题培训班--班级课程
  ClassCourse: {
    url: API_URL + "/Page/ClassCourse",
    data: {
      page: 1,
      rows: 12,
      sort: "Sort",
      order: "desc",
      titleNav: "班级课程",
      wordLimt: 30,
    },
  },
  GetInTraining: {
    url: API_URL + "/traininginfo/GetInTraining?Id=",
  },
  //专题培训班--班级公告
  ClassNoticeList: {
    url: API_URL + "/Page/ClassNoticeList",
    data: {
      page: 1,
      rows: 12,
      sort: "Id",
      order: "desc",
      titleNav: "班级公告",
      wordLimt: 30,
    },
  },
  //专题培训班--班级考试
  ClassExam: {
    url: API_URL + "/Page/ClassExam",
    data: {
      page: 1,
      rows: 12,
      sort: "Id",
      order: "desc",
      titleNav: "班级考试",
      wordLimt: 30,
    },
  },
  //专题培训班--班级文章内容
  ClassArticleDetail: {
    url: API_URL + "/Page/ClassArticleDetail",
    data: {},
  },
  //专题培训班--查看用户权限
  CheckUserClass: {
    url: API_URL + "/Page/CheckUserClass",
    data: {},
  },
  //专题培训班--添加相册
  GetPhotoAlbumAdd: {
    url: API_URL + "/Page/GetPhotoAlbumAdd",
    data: {},
  },
  //专题培训班--添加相册
  PhotoAlbumAdd: {
    url: API_URL + "/Page/PhotoAlbumAdd",
    data: { titleNav: "添加相册" },
  },
  //专题培训班--班级照片
  PhotoPreview: {
    url: API_URL + "/Page/PhotoPreview",
    data: { titleNav: "班级图片", page: 1, rows: 9, sort: "Id", order: "desc" },
  },
  //专题培训班--班级照片
  GetPhotoUpFile: {
    url: API_URL + "/Page/GetPhotoUpFile",
    data: {},
  },
  //专题培训班--添加话题
  ClassTopicAdd: {
    url: API_URL + "/Page/ClassTopicAdd",
    data: { page: 1, rows: 9, sort: "Id", order: "desc" },
  },
  //专题培训班--添加论文
  ClassPaperAdd: {
    url: API_URL + "/Page/ClassPaperAdd",
    data: { page: 1, rows: 9, sort: "Id", order: "desc" },
  },
  //专题培训班--打印证书
  PrintJY: {
    url: API_URL + "/traininginfo/PrintJY",
    data: {},
  },
  //专题培训班--打印学时证明
  PrintXS: {
    url: API_URL + "/traininginfo/PrintXS",
    data: {},
  },
  //原创文章添加（发表心声）
  AddOriginalArticle: {
    url: API_URL + "/Page/AddOriginalArticle",
    data: { Name: "", Content: "" },
  },
  //原创文章列表(学员心声)
  OriginalArticleList: {
    url: API_URL + "/Page/OriginalArticleList",
    data: {
      page: 1,
      rows: 9,
      sort: "Id",
      order: "desc",
      titleNav: "学员心声列表",
    },
  },
  //专题培训班--获取分类
  GetTrainingArticleCategory: {
    url: API_URL + "/Page/GetTrainingArticleCategory",
    data: {},
  },
  //专题培训班--发布文章
  ClassPublishArticle: {
    url: API_URL + "/Page/ClassPublishArticle",
    data: {},
  },
  //考试中心--提交考试  问卷提交
  PostExam: {
    url: API_URL + "/Exam/PostExam",
    data: {},
  },
  //邮箱找回密码
  GetPasswordEmail: {
    url: API_URL + "/Page/GetPasswordEmail",
    data: {},
  },
  //获取密保
  GetQuestion: {
    url: API_URL + "/Page/GetQuestion",
    data: { account: "" },
  },
  //注册
  Register: {
    url: API_URL + "/Info/Register",
    data: {
      // account: '', name: '', password: '', mobile: '', idcard: '', email: '', groupid: '', smgcode: ''
    },
  },
  //发送验证码
  RegisterSendMsg: {
    url: API_URL + "/Info/RegisterSendMsg",
    data: {},
  },
  //获取单位（注册）
  GetGroupList: {
    url: API_URL + "/common/GetGroupList",
    data: { id: "" },
  },
  //获取行业类别（注册）
  GetProfessionType: {
    url: API_URL + "/info/GetProfessionType",
    data: {},
  },
  //发送验证码
  SendMsg: {
    url: API_URL + "/Info/SendMsg",
    data: { mobileNo: "" },
  },
  //提交问题
  GetPasswordByQuestion: {
    url: API_URL + "/Page/GetPasswordByQuestion",
    data: { account: "", question: "", answer: "" },
  },
  //提示未读通知
  UnReadNotice2: {
    url: API_URL + "/Page/UnReadNotice",
    data: {},
  },
  //播放信息
  Play: {
    url: API_URL + "/Home/Play",
    data: { id: "" },
  },
  //试看播放信息
  TryPlay: {
    url: API_URL + "/Play/TryPlay",
    data: { id: "" },
  },
  //刷新播放进度
  Refresh: {
    url: API_URL + "/CourseProcess/Refresh",
    data: { PortalId: "", userId: "", courseid: "" },
  },
  //添加评论
  CourseCommentAdd: {
    url: API_URL + "/Page/CourseCommentAdd",
    data: { mainId: "", parentId: "0", content: "", rate: "" },
  },
  //获取评论
  CourseComment: {
    url: API_URL + "/Page/CourseComment",
    data: { id: "", page: "1", rows: "100", sort: "Id", order: "Desc" },
  },

  //播放前调用
  ClearPlayingCourse: {
    url: API_URL + "/Page/ClearPlayingCourse",
    data: { userid: "" },
  },
  //播放plYjwplay
  PlayJwplay: {
    url: API_URL + "/Home/PlayJwplay",
    data: { courseid: "" },
  },
  //单视频播放进度
  SingleProcess: {
    url: API_URL + "/CourseProcess/SingleProcess",
    data: { PortalId: "", userid: "", courseid: "", positionen: "" },
  },
  //播放精英视频
  PlayJY: {
    url: API_URL + "/Home/PlayJY",
    data: { courseId: "" },
  },
  //播放Scorm视频
  PlayScorm: {
    url: API_URL + "/Home/PlayScorm",
    data: {},
  },
  //播放Scorm视频
  PlayJyScorm: {
    url: API_URL + "/Home/PlayJyScorm",
    data: { id: "" },
  },
  //播放Single视频
  PlaySingle: {
    url: API_URL + "/Home/PlaySingle",
    data: { courseId: "" },
  },
  //播放PlayOffice视频
  PlayOffice: {
    url: API_URL + "/Home/PlayOffice",
    data: { courseId: "" },
  },
  //PlayOffice视频进度提交
  ProcessOffice: {
    url: API_URL + "/CourseProcess/ProcessOffice",
    data: { course_id: "", lesson_id: "", user_id: "", total_id: "" },
  },
  //图书分类
  BookCategory: {
    url: API_URL + "/Page/BookCategory",
    data: { sort: "Sort", categoryId: 0, titleNav: "图书分类" },
  },
  //图书分类搜索
  BookList: {
    url: API_URL + "/Page/BookList",
    data: {
      page: 1,
      rows: 8,
      sort: "Sort",
      order: "asc",
      categoryId: "",
      titleNav: "电子书",
      wordLimt: 35,
      ptitle: "",
      title: "",
    },
  },
  //图书详情内容
  BookContent: {
    url: API_URL + "/Page/BookContent",
    data: {
      page: 1,
      rows: 8,
      sort: "Sort",
      order: "asc",
      id: "",
      titleNav: "图书详细",
      wordLimt: 35,
    },
  },
  //图书章节
  BookChapterList: {
    url: API_URL + "/Page/BookChapterList",
    data: {
      page: 1,
      rows: 8,
      sort: "Sort",
      order: "asc",
      bookId: "",
      titleNav: "图书章节",
    },
  },
  //图书章节内容
  BookChapterContent: {
    url: API_URL + "/Page/BookChapterContent",
    data: { Id: "" },
  },
  //添加图书评论
  BookCommentAdd: {
    url: API_URL + "/Page/BookCommentAdd",
    data: { bookId: "", content: "" },
  },
  //获取图书评论
  BookCommentList: {
    url: API_URL + "/Page/BookCommentList",
    data: {
      page: 1,
      rows: 4,
      sort: "Sort",
      order: "desc",
      bookId: "",
      titleNav: "图书评论",
      wordLimt: 35,
    },
  },
  //学院概况
  CollegeInfo: {
    url: API_URL + "/page/CollegeInfo",
    data: { sort: "Sort" },
  },
  //学员风采
  StudentStyle: {
    url: API_URL + "/Page/StudentStyle",
    data: {
      page: 1,
      rows: 9,
      sort: "Sort",
      order: "desc",
      titleNav: "学员风采",
    },
  },
  //查看购物车
  GetMyShoppingCart: {
    url: API_URL + "/shop/GetMyShoppingCart",
    data: {},
  },
  //添加课程到购物车
  AddToCart: {
    url: API_URL + "/shop/AddToCart",
    data: { ids: "" },
  },
  //购物车中删除
  DelFromCart: {
    url: API_URL + "/shop/DelFromCart",
    data: { id: "" },
  },
  //生成订单
  AddOrder: {
    url: API_URL + "/shop/AddOrder",
    data: {},
  },
  //删除订单
  DelOrder: {
    url: API_URL + "/shop/DelOrder",
    data: { orderId: "" },
  },
  //取消订单
  CancelOrder: {
    url: API_URL + "/shop/CancelOrder",
    data: { orderId: "" },
  },
  //再次购买
  RestoreOrder: {
    url: API_URL + "/shop/RestoreOrder",
    data: { orderId: "" },
  },
  //订单列表
  GetMyOrder: {
    url: API_URL + "/MShop/GetMyOrder",
    data: {},
  },
  //订单详细
  GetOrderDetail: {
    url: API_URL + "/shop/GetOrderDetail",
    data: {
      page: 1,
      rows: 5,
      sort: "Sort",
      order: "desc",
      titleNav: "订单详细",
      orderId: "",
    },
  },
  //确认支付
  BeginPay: {
    url: API_URL + "/shop/BeginPay",
    data: { orderId: "", payType: "" },
  },
  // 完成支付
  EndPay: {
    url: API_URL + "/shop/EndPay",
    data: { payType: "" },
  },
  EndPay2: {
    url: API_URL + "/shop/EndPay2",
    data: { payType: "" },
  },
  EndPay3: {
    url: API_URL + "/shop/EndPay3",
    data: { payType: "" },
  },
  //确认支付（用于生成票据）
  InvoiceBeginPay: {
    url: API_URL + "/MShop/InvoiceBeginPay",
    data: {},
  },
  //获取票据
  GetInvoice: {
    url: API_URL + "/MShop/GetInvoice",
    data: {},
  },
  //最终确认支付
  InvoiceFinishPay: {
    url: API_URL + "/shop/InvoiceFinishPay",
    data: { invoiceId: "", payCode: "" },
  },
  //支付宝支付接口
  PayForInvoice: {
    url: API_URL + "/shop/PayForInvoice",
    data: { invoiceId: "" },
  },
  //添加班级说说
  AddTrainingSay: {
    url: API_URL + "/page/AddTrainingSay",
    data: { mainId: 0, parentId: 0, content: "" },
  },
  //删除班级说说
  DelTrainingSay: {
    url: API_URL + "/page/DelTrainingSay",
    data: { id: "" },
  },
  //获取班级说说列表
  TrainingSayList: {
    url: API_URL + "/page/TrainingSayList",
    data: { mainId: "", sort: "sort", order: "desc", page: 1, rows: 5 },
  },
  //判断用户是否存在
  CheckAccountExit: {
    url: API_URL + "/info/CheckAccountExit",
    data: {},
  },
  //发送验证码
  sendMsg: {
    url: API_URL + "/info/SendMsg",
    data: {},
  },
  //提交验证码
  CheckValidateCode: {
    url: API_URL + "/info/CheckValidateCode",
    data: {},
  },
  //忘记密码 找回密码
  ForgetToUpdatePwd: {
    url: API_URL + "/info/ForgetToUpdatePwd",
    data: {},
  },
  //获取首页轮播图
  GetLink: {
    url: API_URL + "/Page/GetLink",
    data: {},
  },
  //人脸验证
  CheckFace: {
    url: API_URL + "/face/CheckFace",
    data: {},
  },
  // 点击付款
  PcPayOrder: {
    url: API_URL + "/AliPay/PcPayOrder",
    data: {},
  },
  // 跳转百万信息系统
  LoginTrainingImfoSystem: {
    url: API_URL + "/info/LoginTrainingImfoSystem",
    data: {},
  },
  ActivityProgressCreate: {
    url: API_URL + "/info/ActivityProgressCreate",
    data: {},
  },
  ActivityProgressDetail: {
    url: API_URL_ADMIN + "/user/ActivityProgressDetail",
    data: {},
  },
  // GetMachine
  GetMachine: {
    url: API_URL + "/page/GetMachine",
    data: {},
  },
  // 获取首页考试模块
  GetExamType: {
    url: API_URL + "/traininginfo/GetExamType",
    data: {},
  },
  //上传附件
  ToLead: {
    url: API_URL + "/MFile/Tolead",
    data: {},
  },
  //获取上传附件的列表
  TrainingUserImportList: {
    url: API_URL + "/traininginfo/TrainingUserImportList",
  },
  // 集体报名
  TrainingUserSaveImportAsyncNew: {
    url: API_URL + "/traininginfo/TrainingUserSaveImportAsyncNew",
    data: {},
  },

  ReportTrainingUserStudyDetail: {
    url: API_URL + "/admin/Report/ReportTrainingUserStudyDetail",
    data: {},
  },
  //上传识别返回的信息
  GetIdCardDetail: {
    url: API_URL + "/info/GetIdCardDetail",
    data: {},
  },
  //获取报名信息
  TrainingUserDetail: {
    url: API_URL + "/traininginfo/TrainingUserDetail?trainingid=",
  },
  //获取审核信息
  TrainingUserAuditDetail: {
    url: API_URL_ADMIN + "/training/TrainingUserAuditDetail?trainingid=",
  },
  //审核
  TrainingUserpPrsonalAudit: {
    url: API_URL_ADMIN + "/training/TrainingUserpPrsonalAudit",
    data: {},
  },
  //获取订单报名人列表
  GetTrainingUser: {
    url: API_URL + "/MShop/GetTrainingUser",
    data: {},
  },
  //微信支付
  WeChatPay: {
    url: API_URL + "/Pay/WeChatPay?orderId=",
  },
  //获取订单支付状态
  CheckPayStatus: {
    url: API_URL + "/Pay/CheckPayStatus?orderId=",
  },
  //我的证书列表
  TrainingUserPrintList: {
    url: API_URL + "/traininginfo/TrainingUserPrintList",
    data: {},
  },
  //获取时间段内的人数
  GetCheckTimeUserCount: {
    url: API_URL_ADMIN + "/training/GetCheckTimeUserCount",
    data: {},
  },
  //后台报名表列表
  TrainingUserDetailList: {
    url: API_URL + "/traininginfo/TrainingUserDetailList?trainingid=",
    data: {},
  },
  CheckPay: {
    url: API_URL + "/Pay/CheckPay?orderId=",
    data: {},
  },
  PrintJYAllByTraining: {
    url: API_URL + "/admin/Training/PrintJYAllByTraining",
    data: {},
  },
};

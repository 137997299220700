import Vue from "vue";
import App from "./App.vue";
import routes from "./router/index.js";
import store from "./store";
import ElementUI, { MessageBox } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueRouter from "vue-router";
import { Authorization, KeepOnline } from "@/service/getData";
Vue.use(VueRouter);
import moment from 'moment'
Vue.prototype.$moment = moment
// 引入公共样式
import "./styles/base.less";
import axios from "axios";
import VueQuillEditor from "vue-quill-editor";
//引入富文本css
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
//打印dom元素
import Print from "vue-print-nb";
//富文本编辑器添加实例
Vue.use(VueQuillEditor);
import "@/assets/iconfont/iconfont.css";
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.use(ElementUI);
// Vue.prototype.prefix = 'http://test90.jy365.net/Content/Upload/Image/Course/'
Vue.prototype.defalutImg =
  "this.src='https://pic.imgdb.cn/item/62c7eda8f54cd3f937028023.png'";

Vue.use(Print);
// 中国标准时间戳转日期
Vue.prototype.$getTime = function (timestamp) {
  var date = new Date(
    parseInt(timestamp.replace("/Date(", "").replace(")/", ""), 10)
  );
  //getMonth()从0开始算
  var month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var currentDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return date.getFullYear() + "-" + month + "-" + currentDate;
};
//时间戳转换时间
Vue.prototype.$timestamp = function (time) {
  var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // return Y + M + D + h + m + s;
  return Y + M + D;
};

// // 检查用户登录状态
// const getLoginStatus = (currentUrl) => {
//   // let userInfo = store.state.userInfo

//   Authorization({ Controller: 'Do', action: currentUrl }).then(res => {
//     // console.log(res)
//     if (res.Data && res.Data.isauth) {

//       // 清除定时器
//       let timer = null
//       clearInterval(timer)

//       // 每60s请求一次后台 保持用户登录状态
//       timer = setInterval(async ()=> {

//          let res1 =await KeepOnline({
//           date:(new Date()).getTime()
//         })
//       }, 60000)
//     } else {
//       if (res.Type == 3) {
//         MessageBox.confirm('在其他设备上已经登录', '', {
//           confirmButtonText: '确定',
//           callback: router.push({ name: 'login', query: { currentUrl: currentUrl } })
//         })
//       } else if (res.Type == 1) {
//           router.push({ name: 'login', query: { currentUrl: currentUrl } })
//       } else if (res.Type == 9) {
//         MessageBox.confirm('在其他平台登录或被其他人登录', '', {
//           confirmButtonText: '确定',
//           callback: router.push({ name: 'login', query: { currentUrl: currentUrl } })
//         })
//       } else if (res.Type == 10) {
//         MessageBox.confirm('您还不是本平台会员，将前往您所在的平台' + ':' + res.Message, '', {
//           confirmButtonText: '确定',
//           callback: window.open('http://' + res.Message, '_blank')
//         })
//       } else if (res.Type == 11) {
//         MessageBox.confirm('请登录', '', {
//           confirmButtonText: '确定',
//           callback: router.push({ name: 'login', query: { currentUrl: currentUrl } })
//         })
//       } else if (res.Type == 13) {
//         MessageBox.confirm(res.Message, '', {
//           confirmButtonText: '确定',
//           callback: router.push({ name: 'login', query: { currentUrl: currentUrl } })
//         })
//       } else if (res.Type == 15) {
//         MessageBox.confirm(res.Type + ':' + res.Message, '', {
//           confirmButtonText: '确定'
//         }).then(() => {
//         })
//       } else if (res.Type == 20) {
//         // 登陆之后，跳转问卷调查
//       } else {
//         MessageBox.confirm('请登录', '', {
//           confirmButtonText: '确定',
//           cancelButtonText: ''
//         }).then(() => {
//           router.push({ name: 'login', query: { currentUrl: currentUrl } })
//         })
//       }
//     }
//   })
// }

const router = new VueRouter({
  routes,
});
router.beforeEach((to, form, next) => {
  let modifyPassword = localStorage.getItem("modifyPassword");
  if (modifyPassword && form.path == "/pwdEdit") return;
  next();
});
// router.beforeEach((to, from, next) => {
//   store.dispatch('getUserAgent')
//   let fromUrl = from.fullPath
//   let href = window.location.href
//   if (!/\/favicon\.ico/.test(href)) {
//     localStorage.setItem('fromUrl', fromUrl)
//   }
//   if (!to.meta.isSkip) {
//     if (JSON.stringify(store.state.userInfo) === '{}') {
//       next({ path: '/login' })
//     }
//   }
//   next()
// })
router.afterEach((route) => {
  //将滚动条恢复到最顶端
  window.scrollTo(0, 0);
});
// router.afterEach((to, from) => {
//   // 存入当前url
//   let currentUrl = to.fullPath
//   let href = window.location.href
//   if (!/\/favicon\.ico/.test(href)) {
//     localStorage.setItem('currentUrl', currentUrl)
//   }
//   if (!to.meta.isSkip) {
//     getLoginStatus(currentUrl)
//   }
// })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
